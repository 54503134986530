import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import TextField2 from "../../TextField/Style2";
import CoinInfoStyle5 from "../../CoinInfo/Style5";

// redux
import { useDispatch, useSelector } from "react-redux";
import { setYieldStrategyToken } from "../../../store/features/borrowLoan/borrowLoanSlice";
import { YIELD_TOKENS } from "../../../constants/tokens";
import { useERC20ContractFunctions } from "../../../hooks/useERC20Contract";

const YieldStrategy = ({ closeHandler }) => {
  const dispatch = useDispatch();
  const activeWallet = useSelector((state) => state.activeWallet);
  const { balanceOf } = useERC20ContractFunctions();
  // const [balances, setBalances] = useState({});

  const [searchInput, setSearchInput] = useState({
    name: "searchInput",
    value: "",
    placeholder: "Search",
    error: false,
    icon: "x_search-1",
  });

  const inputChangeHandler = (event) => {
    const { value } = event.target;

    setSearchInput({
      ...searchInput,
      value,
    });
  };

  const selectToken = (token) => {
    dispatch(setYieldStrategyToken(token));
    closeHandler();
  };

  const getTokenBalance = async (tokenId, walletAddress) => {
    try {
      return await balanceOf(tokenId, walletAddress);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   YIELD_TOKENS.length &&
  //     YIELD_TOKENS.forEach( (token) => {
  //       balanceOf(token?.id, activeWallet?.address).then((value) => {
  //         // let balancesClone = balances;
  //         // balancesClone = { ...balancesClone, [token?.id]: value };
  //         setBalances({ ...balances, [token?.id]: value });
  //       });
  //     });
  // }, []);

  // useEffect(() => {
  //   console.log(balances);
  // }, [balances]);

  return (
    <div className={styles.yieldStrategy}>
      <div className={styles.yieldStrategy__wrapper}>
        <div className={styles.yieldStrategy__header}>
          <h2 className={`${styles.yieldStrategy__title} heading-3`}>
            Yield Strategy
          </h2>

          <span
            className={styles.yieldStrategy__closeBtn}
            onClick={closeHandler}
          >
            <span className="x_close-1"></span>
          </span>
        </div>

        <div className={styles.yieldStrategy__content}>
          <TextField2
            name={searchInput.name}
            value={searchInput.value}
            error={searchInput.error}
            placeholder={searchInput.placeholder}
            icon={searchInput.icon}
            changeHandler={inputChangeHandler}
          />

          {/* <div className="exchangeBox__seperator"></div> */}

          <div className={styles.yieldStrategy__tokenListing}>
            <ul className={styles.yieldStrategy__tokenListing__list}>
              {YIELD_TOKENS?.length &&
                YIELD_TOKENS.map((token, index) => {
                  return (
                    <li
                      key={token?.id || index}
                      className={styles.yieldStrategy__tokenListing__item}
                    >
                      <CoinInfoStyle5
                        icon={token.logoUri}
                        data={token}
                        getBalance={() =>
                          getTokenBalance(token?.id, activeWallet?.address)
                        }
                        clickHandler={selectToken}
                      />
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YieldStrategy;
