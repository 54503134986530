import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";

/* ----- Third Party Packages ----- */
import { Link } from "react-router-dom";

/* ----- Custom Components ----- */
import Status from "../Status";
import Progress from "../Progress";

/* ----- Redux ----- */
import { useSelector } from "react-redux";

/* ----- Hooks ----- */
import { ALL_CONTRACT_ADDRESSES } from "../../contract";
import { useZeroLiquidContract } from "../../hooks/useZeroLiquidContract";

/* ----- Third Party Packages ----- */
import { BigNumber } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { BigNumber as MyBigNumber } from "bignumber.js";

const PositionItem = ({
  name,
  symbol,
  icon,
  progressTitle,
  showActivePositions,
  tooltipText,
}) => {
  /* ------ Redux ------- */
  const activeWallet = useSelector((state) => state.activeWallet);
  const yieldStrategyToken = useSelector(
    (state) => state.borrowLoan.yieldStrategyToken
  );

  /* ---------- Hooks -------- */
  const { chainId } = useWeb3React();
  const { getRealizedDebt, getShares, getUnderlyingTokenFromShares } =
    useZeroLiquidContract(
      ALL_CONTRACT_ADDRESSES[chainId && chainId]?.PROXY_ZERO_LIQUID
    );

  // States
  const [isDebtActive, setIsDebtActive] = useState(false);
  const [totalReliazedDebt, setTotalReliazedDebt] = useState("0");
  const [totalShares, setTotalShares] = useState("0");
  const [totalUnderlyingAmount, setTotalUnderlyingAmount] = useState("0");
  const [positionProgress, setPositionProgress] = useState("0");

  /* -------- Functions --------- */
  const setDebtStatus = async (walletAddress) => {
    try {
      if (walletAddress) {
        const realizedDebt = await getRealizedDebt(walletAddress);
        setIsDebtActive(BigNumber.from(realizedDebt).gt("0"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRealizedDebtFunc = async (walletAddress) => {
    const result = await getRealizedDebt(walletAddress);
    setTotalReliazedDebt(result);
    return result;
  };

  const getSharesFunc = async (walletAddress, yieldTokenId) => {
    const result = await getShares(walletAddress, yieldTokenId);
    setTotalShares(result);
    return result;
  };

  /* ----- useEffects ----- */
  useEffect(() => {
    if (activeWallet?.address && activeWallet?.isConnected) {
      getRealizedDebtFunc(activeWallet.address);
      setDebtStatus(activeWallet.address);

      if (yieldStrategyToken?.id) {
        getSharesFunc(activeWallet.address, yieldStrategyToken.id).then(
          (shares) => {
            getUnderlyingTokenFromShares(yieldStrategyToken.id, shares).then(
              (underlyingTokenAmount) => {
                setTotalUnderlyingAmount(underlyingTokenAmount);
              }
            );
          }
        );
      }
    }
  }, [activeWallet, yieldStrategyToken]);

  useEffect(() => {
    if (
      BigNumber.from(totalShares).gt("0") &&
      BigNumber.from(totalUnderlyingAmount).gt("0")
    ) {
      const limit = new MyBigNumber(totalUnderlyingAmount).div("10");
      const reliazedDebt = new MyBigNumber(totalReliazedDebt);
      const progressValue = limit
        .minus(reliazedDebt)
        .div(limit)
        .multipliedBy("100")
        .toFormat(2);

      setPositionProgress(progressValue);
    }
  }, [totalReliazedDebt, totalShares, totalUnderlyingAmount]);

  return (
    <Link
      to={`/positions/${symbol}`}
      className={`${styles.positionItem} exchangeBox`}
      style={{
        display: showActivePositions && !isDebtActive ? "none" : "block",
      }}
    >
      <div className={`${styles.positionItem__wrapper} exchangeBox__wrapper`}>
        <div className={styles.coinInfo}>
          <div className={styles.coinInfo__wrapper}>
            <div className={styles.coinInfo__icon}>
              <img
                src={
                  icon ||
                  "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
                }
                alt="Coin Logo"
              />
            </div>

            <div className={styles.coinInfo__typography}>
              <span className={styles.coinInfo__name}>{symbol || ""}</span>
              <span className={styles.coinInfo__symbol}>{name || ""}</span>
            </div>
          </div>
        </div>

        <div className={styles.transaction}>
          <Progress
            statusID={isDebtActive ? "1" : "3"}
            value={Number(positionProgress || 0)}
            maxValue={100}
            title={progressTitle}
          />
        </div>

        <div className={styles.transactionStatus}>
          <Status
            statusID={isDebtActive ? "1" : "3"}
            statusTitle={isDebtActive ? "Active" : "Close"}
            tooltipText={tooltipText}
          />
        </div>
      </div>
    </Link>
  );
};

export default PositionItem;
