import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";

/* ----- Custom Components ----- */
import Listing from "../../components/Listing";
import SpinnerS1 from "../../components/Spinner/Style1";

/* ----- Third Party Packages ----- */
import { BigNumber } from "ethers";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

/* ----- Constants ----- */
import { YIELD_TOKENS } from "../../constants/tokens";
import { ALL_CONTRACT_ADDRESSES } from "../../contract";

/* ----- Hooks ----- */
import { useZeroLiquidContract } from "../../hooks/useZeroLiquidContract";

/* ----- Redux ----- */
import { useSelector, useDispatch } from "react-redux";
import { setYieldStrategyToken } from "../../store/features/borrowLoan/borrowLoanSlice";

/* ----- Utilities ----- */
import { isObjectEmpty } from "../../utilities/helperFunction";

const Positions = () => {
  /* ----- Redux ----- */
  const dispatch = useDispatch();
  const activeWallet = useSelector((state) => state.activeWallet);
  const yieldStrategyToken = useSelector(
    (state) => state.borrowLoan.yieldStrategyToken
  );

  /* ----- States ----- */
  const [totalReliazedDebt, setTotalReliazedDebt] = useState("0");
  const [totalShares, setTotalShares] = useState("0");
  const [showActivePositions, setShowActivePositions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /* ----- Hooks ----- */
  const navigate = useNavigate();
  const { chainId } = useWeb3React();
  const { getShares, getRealizedDebt } = useZeroLiquidContract(
    ALL_CONTRACT_ADDRESSES[chainId]?.PROXY_ZERO_LIQUID
  );

  /* ----- Functions ----- */
  const getRealizedDebtFunc = async (walletAddress) => {
    const result = await getRealizedDebt(walletAddress);
    setTotalReliazedDebt(result);
    return result;
  };

  const getSharesFunc = async (walletAddress, yieldTokenId) => {
    const result = await getShares(walletAddress, yieldTokenId);
    setTotalShares(result);
    return result;
  };

  const redirectToLoan = () => {
    navigate("/");
  };

  /* ----- useEffects ----- */
  // On page load, if yieldStrategyToken is Empty, Set Default Tokens
  useEffect(() => {
    if (isObjectEmpty(yieldStrategyToken)) {
      dispatch(setYieldStrategyToken(YIELD_TOKENS[0]));
    }
  }, []);

  useEffect(() => {
    if (activeWallet?.address) {
      setIsLoading(true);
      getRealizedDebtFunc(activeWallet.address);

      if (yieldStrategyToken?.id) {
        getSharesFunc(activeWallet.address, yieldStrategyToken.id).then(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, [1000]);
        });
      }
    }
  }, [activeWallet, yieldStrategyToken]);

  useEffect(() => {
    if (
      BigNumber.from(totalShares).gt("0") ||
      BigNumber.from(totalReliazedDebt).gt("0")
    ) {
      setShowActivePositions(true);
    } else {
      setShowActivePositions(false);
    }
  }, [totalShares, totalReliazedDebt]);

  return (
    <div>
      <div className="container">
        <div className={`${styles.positionsPage} section padding-top-none`}>
          <div className={`${styles.positionsPage__wrapper} exchangeBox`}>
            <div className="exchangeBox__wrapper">
              <div className={styles.positionsHeader}>
                <div className={styles.positionsHeader__wrapper}>
                  <div className={styles.positionsHeader__left}>
                    <h2
                      className={`heading-3 ${styles.positionsHeader__title}`}
                    >
                      Your Positions
                    </h2>
                  </div>

                  <div className={styles.positionsHeader__right}>
                    <div className={styles.positionsHeader__cta}>
                      <button
                        type="button"
                        className="button1"
                        onClick={redirectToLoan}
                      >
                        <span className="button1__text">New Loan</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className={styles.positionsSeperator}>
                <div className="exchangeBox__seperator"></div>
              </div> */}

              <div className={styles.positionsListing}>
                {activeWallet?.address && showActivePositions ? (
                  <div className={styles.positionsListing__content}>
                    <Listing />
                  </div>
                ) : (
                  <div className={styles.positionsListing__noContentFound}>
                    <p className="body-1">
                      Your active positions will appear here.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className={styles.loading}>
          <div className={styles.loading__overlay}></div>
          <div className={styles.loading__spinner}>
            <div>
              <SpinnerS1 />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Positions;
