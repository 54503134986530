import React from "react";
import styles from "./index.module.scss";
import { NavLink } from "react-router-dom";

const BottomNavigation = () => {
  return (
    <div className={styles.bottomNavigation}>
      <ul className={styles.bottomNavigation__list}>
        <li className={styles.bottomNavigation__item}>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            Loans
          </NavLink>
        </li>
        <li className={styles.bottomNavigation__item}>
          <NavLink
            to="/positions"
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            Positions
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default BottomNavigation;
