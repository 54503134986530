import React from "react";
import styles from "./index.module.scss";
import SpinnerS3 from "../../Spinner/Style3";

const TransactionStatusS2 = ({
  status,
  icon,
  content,
  showCta,
  externalLink,
  toggleDialog,
  item1,
  item2,
}) => {
  return (
    <div className={styles.transactionStatus}>
      <div className={styles.transactionStatus__wrapper}>
        <div
          className={`${styles.transactionStatus__icon} 
          ${styles[`transactionStatus__icon--${status}`]}`}
        >
          {status === "awaiting" || status === "pending" ? (
            <SpinnerS3 />
          ) : (
            <span className={icon}></span>
          )}
        </div>

        <div className={styles.transactionStatus__text}>
          <p>{content || ""}</p>
        </div>

        <div className={styles.transactionStatus__illustration}>
          <div className={styles.illustration}>
            <div className={styles.illustration__from}>
              <div className={styles.illustration__coinInfo}>
                {item1?.icon && (
                  <div className={styles.illustration__image}>
                    <figure>
                      <img
                        src={
                          item1?.icon ||
                          "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
                        }
                        alt={item1?.title || "Token"}
                      />
                    </figure>
                  </div>
                )}

                <div className={styles.illustration__text}>
                  <p>{item1?.amount || ""}</p>
                </div>
              </div>
            </div>

            <div className={styles.illustration__arrow}>
              <span className="x_arrow-right-1"></span>
            </div>

            <div className={styles.illustration__to}>
              <div className={styles.illustration__coinInfo}>
                {item2?.icon && (
                  <div className={styles.illustration__image}>
                    <figure>
                      <img
                        src={
                          item2?.icon ||
                          "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
                        }
                        alt={item2?.title || ""}
                      />
                    </figure>
                  </div>
                )}

                <div className={styles.illustration__text}>
                  <p>{item2?.amount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {showCta && (
          <div className={styles.transactionStatus__cta}>
            <button type="button" className="button1" onClick={toggleDialog}>
              <span className="button1__text">Dismiss</span>
            </button>
          </div>
        )} */}

        {externalLink.title && (
          <div>
            {externalLink.path ? (
              <a
                href={externalLink.path}
                target="_blank"
                className={styles.transactionStatus__externalLink}
              >
                <span className={styles.transactionStatus__externalLink__text}>
                  {externalLink.title}
                </span>
                <span
                  className={`x_external-link ${styles.transactionStatus__externalLink__icon}`}
                ></span>
              </a>
            ) : (
              <span className={styles.transactionStatus__externalLink}>
                <p className={styles.transactionStatus__externalLink__text}>
                  {externalLink.title}
                </p>
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionStatusS2;
