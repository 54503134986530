import React from "react";
import styles from "./index.module.scss";
import SpinnerS1 from "../../Spinner/Style1";

const TransactionStatusS1 = ({
  status,
  icon,
  content,
  showCta,
  externalLink,
  toggleDialog,
}) => {
  return (
    <div className={styles.transactionStatus}>
      <div className={styles.transactionStatus__wrapper}>
        <div
          className={`${styles.transactionStatus__icon} 
          ${styles[`transactionStatus__icon--${status}`]}`}
        >
          {status === "awaiting" || status === "pending" ? (
            <SpinnerS1 />
          ) : (
            <span className={icon}></span>
          )}
        </div>

        <div className={styles.transactionStatus__text}>
          <p>{content || ""}</p>
        </div>

        {showCta && (
          <div className={styles.transactionStatus__cta}>
            <button type="button" className="button1" onClick={toggleDialog}>
              <span className="button1__text">Dismiss</span>
            </button>
          </div>
        )}

        {externalLink.title && externalLink.path && (
          <div>
            <a
              href={externalLink.path}
              target="_blank"
              className={styles.transactionStatus__externalLink}
            >
              <span className={styles.transactionStatus__externalLink__text}>
                {externalLink.title}
              </span>
              <span
                className={`x_external-link ${styles.transactionStatus__externalLink__icon}`}
              ></span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionStatusS1;
