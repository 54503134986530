import { useCallback } from "react";
import { MaxUint256 } from "@ethersproject/constants";
import { ERC20_ABI } from "../contract";
import { useContract } from "./useContract";
import { Contract, ContractReceipt, ContractTransaction } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { TRANSACTION_EXPLORER } from "../constants/chains";
import { Web3Provider } from "@ethersproject/providers";
import { Token } from "../types";
import { useDispatch } from "react-redux";
import {
  setTransactionStatus,
  setShowTransactionStatus,
  addPendingTransaction,
  subtractPendingTransaction,
  setTransactionConfirmationCTA,
  setSnackbar,
  setShowSnackbar,
} from "../store/features/transactionStatus/transactionStatusSlice";
import { logMessage } from "../utilities/logs";
import ZeroLiquidLogo from "../assets/images/logo-short.png";

export function useERC20Contract(address: string): {
  // gives address, takes token address
  name: () => Promise<string>;
  symbol: () => Promise<string>;
  decimals: () => Promise<number>;
  balanceOf: (address: string) => Promise<string>; // give active user account address => balance
  allowance: (owner: string, spender: string) => Promise<boolean>; // owner = me (active user), spender = zeroliquid (contract address)
  approve: (spender: string, onSuccessCallback: () => void) => Promise<void>;
} {
  const { chainId } = useWeb3React();
  const erc20ContractInstance = useContract(address, ERC20_ABI) as Contract; // Gives contrast instance

  const name = useCallback(async (): Promise<string> => {
    return await erc20ContractInstance.name();
  }, [erc20ContractInstance]);

  const symbol = useCallback(async (): Promise<string> => {
    return await erc20ContractInstance.symbol();
  }, [erc20ContractInstance]);

  const decimals = useCallback(async (): Promise<number> => {
    return await erc20ContractInstance.decimals();
  }, [erc20ContractInstance]);

  const balanceOf = useCallback(
    async (address: string): Promise<string> => {
      return await (await erc20ContractInstance.balanceOf(address)).toString();
    },
    [erc20ContractInstance]
  );

  const allowance = useCallback(
    async (owner: string, spender: string): Promise<boolean> => {
      return await (
        await erc20ContractInstance.allowance(owner, spender)
      ).gt("0");
    },
    [erc20ContractInstance]
  );

  const approve = useCallback(
    async (spender: string, onSuccessCallback: () => void): Promise<void> => {
      // ownwer: me (user), spender: zl Contract
      if (erc20ContractInstance && chainId) {
        // console.log({
        //   heading: "TOKEN",
        //   textPrimary: "APPROVAL PENDING",
        //   actionState: "pending",
        // });

        erc20ContractInstance
          .approve(spender, MaxUint256) // me, number in wei e.g 1 => ethers.utils.parseUnits(1) // ethers.utils.formatUnits()
          .then(function (tx: ContractTransaction) {
            // console.log({
            //   heading: "TOKEN",
            //   textPrimary: "APPROVAL PENDING",
            //   actionState: "pending",
            //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
            //   linkText: "View Transaction",
            // });

            tx.wait(1)
              .then(function (transactionRecipt: ContractReceipt) {
                onSuccessCallback();
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL SUCCESSFULL",
                //   actionState: "success",
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });
              })
              .catch((e: any) => {
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL FAILED",
                //   actionState: "error",
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });
              });
          })
          .catch((e: any) => {
            if (e.code === 4001) {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL REJECTED",
              //   actionState: "error",
              // });
            } else {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL FAILED",
              //   actionState: "error",
              // });
            }
          });
      }
    },
    [erc20ContractInstance, chainId]
  );

  return {
    name,
    symbol,
    decimals,
    balanceOf,
    allowance,
    approve,
  };
}

export function useERC20ContractFunctions(): {
  name: (token: string) => Promise<string>;
  // symbol: (token: string) => Promise<string>;
  // decimals: (token: string) => Promise<number>;
  balanceOf: (token: string, address: string) => Promise<string>;
  allowance: (token: string, owner: string, spender: string) => Promise<string>;
  approve: (
    token: Token,
    spender: string,
    // ApprovalTokenAmount
    amount: string,
    onSuccessCallback: () => void
  ) => Promise<void>;
} {
  const dispatch = useDispatch();
  const { active, account, chainId, library } = useWeb3React<Web3Provider>();

  const name = useCallback(
    async (token: string): Promise<string> => {
      if (active && account && library) {
        const erc20ContractInstance = new Contract(
          token,
          ERC20_ABI,
          library.getSigner(account).connectUnchecked()
        );
        return await erc20ContractInstance.name();
      } else return "Unknown";
    },
    [active, account, library]
  );

  // const symbol = async (token: string): Promise<string> => {
  //   const erc20ContractInstance = new Contract(token, ERC20_ABI);
  //   return await erc20ContractInstance.symbol();
  // };

  // const decimals = async (token: string): Promise<number> => {
  //   const erc20ContractInstance = new Contract(token, ERC20_ABI);
  //   return await erc20ContractInstance.decimals();
  // };

  const balanceOf = useCallback(
    async (token: string, address: string): Promise<string> => {
      logMessage("useERC20ContractFunctions balanceOf", [token, address]);

      if (active && account && library) {
        const erc20ContractInstance = new Contract(
          token,
          ERC20_ABI,
          library.getSigner(account).connectUnchecked()
        );
        return await (
          await erc20ContractInstance.balanceOf(address)
        ).toString();
      } else return "0";
    },
    [active, account, library]
  );

  const allowance = useCallback(
    async (token: string, owner: string, spender: string): Promise<string> => {
      logMessage("useERC20ContractFunctions allowance", [token, spender]);

      if (active && account && library) {
        const erc20ContractInstance = new Contract(
          token,
          ERC20_ABI,
          library.getSigner(account).connectUnchecked()
        );
        return await (
          await erc20ContractInstance.allowance(owner, spender)
        ).toString();
        // .gt("0"); // Add gt 0 & lt balance condition
      } else return "0";
    },
    [active, account, library]
  );

  const approve = useCallback(
    async (
      token: Token,
      spender: string,
      amount: string,
      onSuccessCallback: () => void
    ): Promise<void> => {
      logMessage("useERC20ContractFunctions approve", [
        token,
        spender,
        amount,
        onSuccessCallback,
      ]);

      if (active && account && library && chainId) {
        const erc20ContractInstance = new Contract(
          token.id,
          ERC20_ABI,
          library.getSigner(account).connectUnchecked()
        );

        // console.log({
        //   heading: token.symbol,
        //   textPrimary: "APPROVAL PENDING",
        //   actionState: "pending",
        // });

        dispatch(setShowTransactionStatus(true));
        dispatch(
          setTransactionStatus({
            status: "awaiting",
            icon: "x_loading",
            content: "Awaiting confirmation",
            showCta: false,
            externalLink: {
              title: "",
              path: "",
            },
            item1: {
              title: token?.symbol,
              amount: `${amount} ${token?.symbol}`,
              icon: token?.logoUri,
            },
            item2: {
              title: "ZeroLiquid",
              amount: "ZeroLiquid",
              icon: ZeroLiquidLogo,
            },
          })
        );

        erc20ContractInstance
          .approve(spender, MaxUint256)
          .then(function (tx: ContractTransaction) {
            // console.log({
            //   heading: token.symbol,
            //   textPrimary: "APPROVAL PENDING",
            //   actionState: "pending",
            //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
            //   linkText: "View Transaction",
            // });

            dispatch(addPendingTransaction());
            dispatch(setShowTransactionStatus(true));
            dispatch(
              setTransactionStatus({
                status: "pending",
                icon: "x_loading",
                content: "Approval Pending",
                showCta: false,
                externalLink: {
                  title: "View on Etherscan",
                  path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                },
                item1: {
                  title: token?.symbol,
                  amount: `${amount} ${token?.symbol}`,
                  icon: token?.logoUri,
                },
                item2: {
                  title: "ZeroLiquid",
                  amount: "ZeroLiquid",
                  icon: ZeroLiquidLogo,
                },
              })
            );

            tx.wait(1)
              .then(function (transactionRecipt: ContractReceipt) {
                onSuccessCallback();
                // console.log({
                //   heading: token.symbol,
                //   textPrimary: "APPROVAL SUCCESSFULL",
                //   actionState: "success",
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(false));
                // dispatch(
                //   setTransactionStatus({
                //     status: "success",
                //     icon: "x_check",
                //     content: "Approval Successful",
                //     showCta: true,
                //     externalLink: {
                //       title: "View on Etherscan",
                //       path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //     },
                //     item1: {
                //       title: token?.symbol,
                //       amount: `${amount} ${token?.symbol}`,
                //       icon: token?.logoUri,
                //     },
                //     item2: {
                //       title: "ZeroLiquid",
                //       amount: "ZeroLiquid",
                //       icon: ZeroLiquidLogo,
                //     },
                //   })
                // );
                dispatch(setTransactionConfirmationCTA(""));
                dispatch(setShowSnackbar(true));
                dispatch(
                  setSnackbar({
                    title: "Approved",
                    subTitle: `${amount} ${token?.symbol} in ZeroLiquid`,
                    icon: ZeroLiquidLogo,
                  })
                );
              })
              .catch((e: any) => {
                // console.log({
                //   heading: token.symbol,
                //   textPrimary: "APPROVAL FAILED",
                //   actionState: "error",
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(true));
                dispatch(
                  setTransactionStatus({
                    status: "failed",
                    icon: "x_alert-triangle",
                    content: "Approval Failed",
                    showCta: true,
                    externalLink: {
                      title: "",
                      path: "",
                    },
                    item1: {
                      title: token?.symbol,
                      amount: `${amount} ${token?.symbol}`,
                      icon: token?.logoUri,
                    },
                    item2: {
                      title: "ZeroLiquid",
                      amount: "ZeroLiquid",
                      icon: ZeroLiquidLogo,
                    },
                  })
                );
                dispatch(setTransactionConfirmationCTA(""));
              });
          })
          .catch((e: any) => {
            if (e.code === 4001) {
              // console.log({
              //   heading: token.symbol,
              //   textPrimary: "APPROVAL REJECTED",
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "rejected",
                  icon: "x_alert-triangle",
                  content: "Approval Rejected",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: token?.symbol,
                    amount: `${amount} ${token?.symbol}`,
                    icon: token?.logoUri,
                  },
                  item2: {
                    title: "ZeroLiquid",
                    amount: "ZeroLiquid",
                    icon: ZeroLiquidLogo,
                  },
                })
              );
              dispatch(setTransactionConfirmationCTA(""));
            } else {
              // console.log({
              //   heading: token.symbol,
              //   textPrimary: "APPROVAL FAILED",
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "failed",
                  icon: "x_alert-triangle",
                  content: "Approval Failed",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: token?.symbol,
                    amount: `${amount} ${token?.symbol}`,
                    icon: token?.logoUri,
                  },
                  item2: {
                    title: "ZeroLiquid",
                    amount: "ZeroLiquid",
                    icon: ZeroLiquidLogo,
                  },
                })
              );
              dispatch(setTransactionConfirmationCTA(""));
            }
          });
      }
    },
    [active, account, library, chainId]
  );

  return {
    name,
    balanceOf,
    allowance,
    approve,
  };
}
