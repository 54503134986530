import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { guid } from "../../utilities/helperFunction";

const Switch = ({ checked, changeHandler }) => {
  const [id, setId] = useState("switch");

  useEffect(() => {
    setId(guid());
  }, []);

  return (
    <div>
      <input
        type="checkbox"
        id={id}
        className={styles.checkbox}
        checked={checked}
        onChange={changeHandler}
      ></input>
      <label htmlFor={id} className={styles.toggle}></label>
    </div>
  );
};

export default Switch;
