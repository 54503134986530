import ERC20_ABI from "./abis/ERC20.json";
import ZERO_LIQUID_ABI from "./abis/ZeroProtocol.json";
import WSTETH_ADAPTER_ABI from "./abis/WstETHAdapter.json";

import { SupportedChainId } from "../constants/chains";
// import { ERC20 } from "./typechain/ERC20";

export type CONTRACTS = {
  PROXY_STREAMER_BUFFER: string;
  PROXY_STREAMER: string;
  PROXY_ZERO_LIQUID: string;
  wstETH_ADAPTER: string;
  wstETH__TOKEN_ADDRESS: string;
};

const ALL_CONTRACT_ADDRESSES: { [key in number]: CONTRACTS } = {
  // [SupportedChainId.MAINNET]: {
  //   PROXY_STREAMER_BUFFER: "",
  //   PROXY_STREAMER: "",
  //   PROXY_ZERO_LIQUID: "",
  //   wstETH_ADAPTER: "",
  // },
  [SupportedChainId.GOERLI]: {
    PROXY_STREAMER_BUFFER: "0x0bc984F52C6E63420128F5D0016c969B91D9EFB3",
    PROXY_STREAMER: "0x3312011F079c839F44774974fd183e3bab780A01",
    PROXY_ZERO_LIQUID: "0xeae37bcf618143A5D4176e65d60e7Ed9931e6131",
    wstETH_ADAPTER: "0x5e8ab6280426AB590966b06cb0d7af5EA98bE962",
    wstETH__TOKEN_ADDRESS: "0xf9D689e2aBaC0f531360d16D129fA11B331Dc2e0",
  },
};

export {
  ALL_CONTRACT_ADDRESSES,
  ERC20_ABI,
  ZERO_LIQUID_ABI,
  WSTETH_ADAPTER_ABI,
};

/* CONTRACTS */
/* 
GOERLI TESTNET ADDRESSES

1 WETH9 0xfb7555b2752d1b9c4a3b9febb6168171b13a4c73 // user deposits
2 stETH 0xd35a528ca45ed95869b486de066e74e3c6bb9c2c
3 WstETH 0x783f522af26311c0ef4fc1e283294b1be5319d9b

WHITELIST 0xa9b75f21225c3781813a5452d50a065ef436c26d
4 zETH 0xba0de366f61fc333da9b102e8157e58285da1e8d
PROXY_STEAMER_BUFFER 0xd5b701e9599cbac12b5160d14127049490dd085c
PROXY_STEAMER 0x9688d72f4199fadd450f197893e9c259f7c48885
PROXY_ZERO_LIQUID 0x66bd23353dfc724baf4b30f534d0a977d74f2cb9 // give approval // deposit to proxy zero liquid
WstETH_ADAPTER 0xbc36850810d134bc72f83494d54807da39b06230
*/
