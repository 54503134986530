import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { ContractInterface, Contract } from "ethers";
import { getContractInstance } from "../utilities/contract";

export function useContract<T extends Contract = Contract>(
  address: string,
  ABI: ContractInterface
): T | null {
  const { account, active, library } = useWeb3React<Web3Provider>();

  return useMemo(() => {
    if (!account || !active || !library || !address || !ABI) {
      return null;
    } else return getContractInstance(address, ABI, library, account);
  }, [account, active, library, address, ABI]) as T;
}
