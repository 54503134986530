import React from "react";
import styles from "./index.module.scss";

const InfoText = ({ text, showLink = false }) => {
  return (
    <div className={styles.infoText}>
      <span className="x_info-circle-1"></span>
      <div>
        <p>
          {text}{" "}
          {showLink && (
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeftjylW-G7IlyUbq26BcezHpJNQauT0MT0sOyLTU_1TLYA-g/viewform"
              target="_blank"
            >
              here
            </a>
          )}
        </p>
      </div>
    </div>
  );
};

export default InfoText;
