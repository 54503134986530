import { useCallback } from "react";
import { ALL_CONTRACT_ADDRESSES, ZERO_LIQUID_ABI } from "../contract";
import { useContract } from "./useContract";
import {
  BigNumber,
  Contract,
  ContractReceipt,
  ContractTransaction,
  ethers,
} from "ethers";
import { useWeb3React } from "@web3-react/core";
import { useWstETHAdapterContract } from "./useWstETHAdapterContract";
import { useDispatch } from "react-redux";
import {
  setTransactionStatus,
  setShowTransactionStatus,
  addPendingTransaction,
  subtractPendingTransaction,
  setTransactionConfirmationCTA,
  setSnackbar,
  setShowSnackbar,
} from "../store/features/transactionStatus/transactionStatusSlice";
import { TRANSACTION_EXPLORER } from "../constants/chains";
import { logMessage } from "../utilities/logs";
import { Token } from "../types";
import ZeroLiquidLogo from "../assets/images/logo-short.png";
import { conciseAddress } from "../utilities/helperFunction";

export function useZeroLiquidContract(address: string): {
  depositUnderlying: (
    yieldtoken: Token,
    amount: string,
    recipient: string,
    token2: Token,
    // minimumAmountOut: string,
    onSuccessCallback: () => void
  ) => Promise<void>;
  deposit: (
    yieldtoken: Token,
    amount: string,
    recipient: string,
    token2: Token,
    onSuccessCallback: () => void
  ) => Promise<void>;
  mint: (
    amountDebtToken: string,
    recipient: string,
    token1: Token,
    token2: Token,
    onSuccessCallback: () => void
  ) => Promise<void>;
  repay: (
    underlyingToken: Token,
    amount: string,
    recipient: string,
    onSuccessCallback: () => void
  ) => Promise<void>;
  liquidate: (
    yieldToken: Token,
    shares: string,
    amount: string,
    onSuccessCallback: () => void
  ) => Promise<void>;
  withdrawUnderlying: (
    yieldToken: Token,
    shares: string,
    amount: string,
    recipient: string,
    onSuccessCallback: () => void
  ) => Promise<void>;
  burn: (
    amount: string,
    recipient: string,
    onSuccessCallback: () => void
  ) => Promise<void>;
  getYieldTokenParameters: (yieldTokenId: string) => Promise<Object>;
  getShares: (owner: string, yieldTokenId: string) => Promise<string>;
  getUnrealizedDebt: (owner: string, yieldTokenId: string) => Promise<string>;
  getRealizedDebt: (owner: string) => Promise<string>;
  getUnderlyingTokenFromShares: (
    yieldToken: string,
    shares: string
  ) => Promise<string>;
} {
  const dispatch = useDispatch();
  const { chainId } = useWeb3React();

  const zeroLiquidContractInstance = useContract(
    address,
    ZERO_LIQUID_ABI
  ) as Contract; // Gives contrast instance

  const wstETHAdapterContractInstance = useWstETHAdapterContract(
    ALL_CONTRACT_ADDRESSES[5]?.wstETH_ADAPTER
  );

  const minimumAmountOut = useCallback(
    async (amount: string): Promise<string> => {
      return BigNumber.from(amount)
        .div(await wstETHAdapterContractInstance.price())
        .toString();
    },
    [wstETHAdapterContractInstance]
  );

  // const minimumAmountOutMint = useCallback(
  //   async (amount: string): Promise<string> => {
  //     return (
  //       new MyBigNumber(amount)
  //         // .div(new MyBigNumber(await wstETHAdapterContractInstance.price()))
  //         .multipliedBy("0.75")
  //         .toString()
  //         .split(".")[0]
  //     );

  //     // return BigNumber.from(amount)
  //     //   .div(await wstETHAdapterContractInstance.price())
  //     //   .mul("90")
  //     //   .toString();
  //   },
  //   [wstETHAdapterContractInstance]
  // );

  const getYieldTokenParameters = useCallback(
    async (yieldTokenId: string) => {
      logMessage("getYieldTokenParameters", yieldTokenId);

      const params = await zeroLiquidContractInstance.getYieldTokenParameters(
        yieldTokenId
      );

      return {
        maxValue: params[4].toString(),
        obtainedValue: params[6].toString(),
      };
    },
    [zeroLiquidContractInstance]
  );

  const getShares = useCallback(
    async (owner: string, yieldTokenId: string) => {
      logMessage("getShares", [owner, yieldTokenId]);

      const shares = await zeroLiquidContractInstance.positions(
        owner,
        yieldTokenId
      );

      return shares.shares.toString();
    },
    [zeroLiquidContractInstance]
  );

  const getUnrealizedDebt = useCallback(
    async (owner: string, yieldTokenId: string) => {
      logMessage("getUnrealizedDebt", [owner, yieldTokenId]);

      const shares = getShares(owner, yieldTokenId);

      const underlyingTokens =
        await zeroLiquidContractInstance.convertSharesToUnderlyingTokens(
          yieldTokenId,
          shares
        );

      const totaUnrealizedDebt: BigNumber =
        BigNumber.from(underlyingTokens).div("10");

      const realizedDebt = await getRealizedDebt(owner);

      const unrealizedDebt = totaUnrealizedDebt.sub(
        BigNumber.from(realizedDebt)
      );

      return unrealizedDebt.toString();
    },
    [zeroLiquidContractInstance]
  );

  const getRealizedDebt = useCallback(
    async (owner: string) => {
      logMessage("getRealizedDebt", [owner]);

      const realizedDebt = await zeroLiquidContractInstance.accounts(owner);

      return realizedDebt.debt.toString();
    },
    [zeroLiquidContractInstance]
  );

  const getUnderlyingTokenFromShares = useCallback(
    async (yieldToken: string, shares: string) => {
      logMessage("getUnderlyingTokenFromShares", [yieldToken, shares]);

      const underlyingTokenAmount =
        await zeroLiquidContractInstance.convertSharesToUnderlyingTokens(
          yieldToken,
          shares
        );

      return underlyingTokenAmount.toString();
    },
    [zeroLiquidContractInstance]
  );

  const depositUnderlying = useCallback(
    async (
      yieldtoken: Token,
      amount: string | any,
      recipient: string,
      token2: Token,
      // minimumAmountOut: string,
      onSuccessCallback: () => void
    ): Promise<void> => {
      logMessage("depositUnderlying", [
        yieldtoken,
        amount,
        recipient,
        token2,
        onSuccessCallback,
      ]);

      // ownwer: me (user), spender: zl Contract
      if (zeroLiquidContractInstance && chainId) {
        // console.log({
        //   heading: "Deposit",
        //   textPrimary: "PENDING",
        //   actionState: "pending",
        // });

        dispatch(setShowTransactionStatus(true));
        dispatch(
          setTransactionStatus({
            status: "awaiting",
            icon: "x_loading",
            content: "Awaiting confirmation",
            showCta: false,
            externalLink: {
              title: "",
              path: "",
            },
            item1: {
              title: token2?.symbol,
              amount: `${
                // @ts-ignore
                (ethers.utils.formatEther(amount).toString() * 1).toFixed(5)
              } ${token2?.symbol}`,
              icon: token2?.logoUri,
            },
            item2: {
              title: "ZeroLiquid",
              amount: "ZeroLiquid",
              icon: ZeroLiquidLogo,
            },
          })
        );

        zeroLiquidContractInstance
          .depositUnderlying(
            yieldtoken.id,
            amount,
            recipient,
            await minimumAmountOut(amount)
          ) // me, number in wei e.g 1 => ethers.utils.parseUnits(1) // ethers.utils.formatUnits()
          .then(function (tx: ContractTransaction) {
            // console.log({
            //   heading: "TOKEN",
            //   textPrimary: "APPROVAL PENDING",
            //   actionState: "pending",
            //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
            //   linkText: "View Transaction",
            // });

            dispatch(addPendingTransaction());
            dispatch(setShowTransactionStatus(true));
            dispatch(
              setTransactionStatus({
                status: "pending",
                icon: "x_loading",
                content: "Deposit Pending",
                showCta: false,
                externalLink: {
                  title: "View on Etherscan",
                  path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                },
                item1: {
                  title: token2?.symbol,
                  amount: `${
                    // @ts-ignore
                    (ethers.utils.formatEther(amount).toString() * 1).toFixed(5)
                  } ${token2?.symbol}`,
                  icon: token2?.logoUri,
                },
                item2: {
                  title: "ZeroLiquid",
                  amount: "ZeroLiquid",
                  icon: ZeroLiquidLogo,
                },
              })
            );

            tx.wait(1)
              .then(function (transactionRecipt: ContractReceipt) {
                onSuccessCallback();
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL SUCCESSFULL",
                //   actionState: "success",
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(false));
                // dispatch(
                //   setTransactionStatus({
                //     status: "success",
                //     icon: "x_check",
                //     content: "Deposit Successful",
                //     showCta: true,
                //     externalLink: {
                //       title: "View on Etherscan",
                //       path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //     },
                //     item1: {
                //       title: token2?.symbol,
                //       amount: `${
                //         // @ts-ignore
                //         (ethers.utils.formatEther(amount).toString() * 1).toFixed(5)
                //       } ${token2?.symbol}`,
                //       icon: token2?.logoUri,
                //     },
                //     item2: {
                //       title: "ZeroLiquid",
                //       amount: "ZeroLiquid",
                //       icon: ZeroLiquidLogo,
                //     },
                //   })
                // );
                dispatch(setTransactionConfirmationCTA(""));
                dispatch(setShowSnackbar(true));
                dispatch(
                  setSnackbar({
                    title: "Deposited",
                    subTitle: `${
                      // @ts-ignore
                      (ethers.utils.formatEther(amount).toString() * 1).toFixed(
                        5
                      )
                    } ${token2?.symbol} in ZeroLiquid`,
                    icon: ZeroLiquidLogo,
                  })
                );
              })
              .catch((e: any) => {
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL FAILED",
                //   actionState: "error",
                //   error: e,
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(true));
                dispatch(
                  setTransactionStatus({
                    status: "failed",
                    icon: "x_alert-triangle",
                    content: "Deposit Failed",
                    showCta: true,
                    externalLink: {
                      title: "",
                      path: "",
                    },
                    item1: {
                      title: token2?.symbol,
                      amount: `${
                        (
                          // @ts-ignore
                          ethers.utils.formatEther(amount).toString() * 1
                        ).toFixed(5)
                      } ${token2?.symbol}`,
                      icon: token2?.logoUri,
                    },
                    item2: {
                      title: "ZeroLiquid",
                      amount: "ZeroLiquid",
                      icon: ZeroLiquidLogo,
                    },
                  })
                );
                dispatch(setTransactionConfirmationCTA(""));
              });
          })
          .catch((e: any) => {
            if (e.code === 4001) {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL REJECTED",
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "rejected",
                  icon: "x_alert-triangle",
                  content: "Deposit Rejected",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: token2?.symbol,
                    amount: `${
                      // @ts-ignore
                      (ethers.utils.formatEther(amount).toString() * 1).toFixed(
                        5
                      )
                    } ${token2?.symbol}`,
                    icon: token2?.logoUri,
                  },
                  item2: {
                    title: "ZeroLiquid",
                    amount: "ZeroLiquid",
                    icon: ZeroLiquidLogo,
                  },
                })
              );
              dispatch(setTransactionConfirmationCTA(""));
            } else {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL FAILED",
              //   error: e,
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "failed",
                  icon: "x_alert-triangle",
                  content: "Deposit Failed",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: token2?.symbol,
                    amount: `${
                      // @ts-ignore
                      (ethers.utils.formatEther(amount).toString() * 1).toFixed(
                        5
                      )
                    } ${token2?.symbol}`,
                    icon: token2?.logoUri,
                  },
                  item2: {
                    title: "ZeroLiquid",
                    amount: "ZeroLiquid",
                    icon: ZeroLiquidLogo,
                  },
                })
              );
              dispatch(setTransactionConfirmationCTA(""));
            }
          });
      }
    },
    [zeroLiquidContractInstance, chainId]
  );

  const deposit = useCallback(
    async (
      yieldtoken: Token,
      amount: string | any,
      recipient: string,
      token2: Token,
      onSuccessCallback: () => void
    ): Promise<void> => {
      logMessage("deposit", [yieldtoken, amount, recipient, onSuccessCallback]);

      // ownwer: me (user), spender: zl Contract
      if (zeroLiquidContractInstance && chainId) {
        // console.log({
        //   heading: "Deposit",
        //   textPrimary: "PENDING",
        //   actionState: "pending",
        // });

        dispatch(setShowTransactionStatus(true));
        dispatch(
          setTransactionStatus({
            status: "awaiting",
            icon: "x_loading",
            content: "Awaiting confirmation",
            showCta: false,
            externalLink: {
              title: "",
              path: "",
            },
            item1: {
              title: token2?.symbol,
              amount: `${
                // @ts-ignore
                (ethers.utils.formatEther(amount).toString() * 1).toFixed(5)
              } ${token2?.symbol}`,
              icon: token2?.logoUri,
            },
            item2: {
              title: "ZeroLiquid",
              amount: "ZeroLiquid",
              icon: ZeroLiquidLogo,
            },
          })
        );

        zeroLiquidContractInstance
          .deposit(yieldtoken.id, amount, recipient) // me, number in wei e.g 1 => ethers.utils.parseUnits(1) // ethers.utils.formatUnits()
          .then(function (tx: ContractTransaction) {
            // console.log({
            //   heading: "TOKEN",
            //   textPrimary: "APPROVAL PENDING",
            //   actionState: "pending",
            //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
            //   linkText: "View Transaction",
            // });

            dispatch(addPendingTransaction());
            dispatch(setShowTransactionStatus(true));
            dispatch(
              setTransactionStatus({
                status: "pending",
                icon: "x_loading",
                content: "Deposit Pending",
                showCta: false,
                externalLink: {
                  title: "View on Etherscan",
                  path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                },
                item1: {
                  title: token2?.symbol,
                  amount: `${
                    // @ts-ignore
                    (ethers.utils.formatEther(amount).toString() * 1).toFixed(5)
                  } ${token2?.symbol}`,
                  icon: token2?.logoUri,
                },
                item2: {
                  title: "ZeroLiquid",
                  amount: "ZeroLiquid",
                  icon: ZeroLiquidLogo,
                },
              })
            );

            tx.wait(1)
              .then(function (transactionRecipt: ContractReceipt) {
                onSuccessCallback();
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL SUCCESSFULL",
                //   actionState: "success",
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(false));
                // dispatch(
                //   setTransactionStatus({
                //     status: "success",
                //     icon: "x_check",
                //     content: "Deposit Successful",
                //     showCta: true,
                //     externalLink: {
                //       title: "View on Etherscan",
                //       path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //     },
                //     item1: {
                //       title: token2?.symbol,
                //       amount: `${
                //         // @ts-ignore
                //         (
                //           ethers.utils.formatEther(amount).toString() * 1
                //         ).toFixed(5)
                //       } ${token2?.symbol}`,
                //       icon: token2?.logoUri,
                //     },
                //     item2: {
                //       title: "ZeroLiquid",
                //       amount: "ZeroLiquid",
                //       icon: ZeroLiquidLogo,
                //     },
                //   })
                // );
                dispatch(setTransactionConfirmationCTA(""));
                dispatch(setShowSnackbar(true));
                dispatch(
                  setSnackbar({
                    title: "Deposited",
                    subTitle: `${
                      // @ts-ignore
                      (ethers.utils.formatEther(amount).toString() * 1).toFixed(
                        5
                      )
                    } ${token2?.symbol} in ZeroLiquid`,
                    icon: ZeroLiquidLogo,
                  })
                );
              })
              .catch((e: any) => {
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL FAILED",
                //   actionState: "error",
                //   error: e,
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(true));
                dispatch(
                  setTransactionStatus({
                    status: "failed",
                    icon: "x_alert-triangle",
                    content: "Deposit Failed",
                    showCta: true,
                    externalLink: {
                      title: "",
                      path: "",
                    },
                    item1: {
                      title: token2?.symbol,
                      amount: `${
                        (
                          // @ts-ignore
                          ethers.utils.formatEther(amount).toString() * 1
                        ).toFixed(5)
                      } ${token2?.symbol}`,
                      icon: token2?.logoUri,
                    },
                    item2: {
                      title: "ZeroLiquid",
                      amount: "ZeroLiquid",
                      icon: ZeroLiquidLogo,
                    },
                  })
                );
                dispatch(setTransactionConfirmationCTA(""));
              });
          })
          .catch((e: any) => {
            if (e.code === 4001) {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL REJECTED",
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "rejected",
                  icon: "x_alert-triangle",
                  content: "Deposit Rejected",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: token2?.symbol,
                    amount: `${
                      // @ts-ignore
                      (ethers.utils.formatEther(amount).toString() * 1).toFixed(
                        5
                      )
                    } ${token2?.symbol}`,
                    icon: token2?.logoUri,
                  },
                  item2: {
                    title: "ZeroLiquid",
                    amount: "ZeroLiquid",
                    icon: ZeroLiquidLogo,
                  },
                })
              );
              dispatch(setTransactionConfirmationCTA(""));
            } else {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL FAILED",
              //   error: e,
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "failed",
                  icon: "x_alert-triangle",
                  content: "Deposit Failed",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: token2?.symbol,
                    amount: `${
                      // @ts-ignore
                      (ethers.utils.formatEther(amount).toString() * 1).toFixed(
                        5
                      )
                    } ${token2?.symbol}`,
                    icon: token2?.logoUri,
                  },
                  item2: {
                    title: "ZeroLiquid",
                    amount: "ZeroLiquid",
                    icon: ZeroLiquidLogo,
                  },
                })
              );
              dispatch(setTransactionConfirmationCTA(""));
            }
          });
      }
    },
    [zeroLiquidContractInstance, chainId]
  );

  const mint = useCallback(
    async (
      amountDebtToken: string | any,
      recipient: string,
      token1: Token,
      token2: Token,
      onSuccessCallback: () => void
    ): Promise<void> => {
      logMessage("mint", [amountDebtToken, recipient, onSuccessCallback]);

      if (zeroLiquidContractInstance && chainId) {
        // console.log({
        //   heading: "Deposit",
        //   textPrimary: "PENDING",
        //   actionState: "pending",
        // });

        dispatch(setShowTransactionStatus(true));
        dispatch(
          setTransactionStatus({
            status: "awaiting",
            icon: "x_loading",
            content: "Awaiting confirmation",
            showCta: false,
            externalLink: {
              title: "",
              path: "",
            },
            item1: {
              title: "ZeroLiquid",
              amount: "ZeroLiquid",
              icon: ZeroLiquidLogo,
            },
            item2: {
              title: token2?.symbol,
              amount: `${
                // @ts-ignore
                (ethers.utils.formatUnits(amountDebtToken) * 1).toFixed(5)
              } ${token2?.symbol}`,
              icon: token2?.logoUri,
            },
          })
        );

        zeroLiquidContractInstance
          .mint(amountDebtToken, recipient)
          .then(function (tx: ContractTransaction) {
            // console.log({
            //   heading: "TOKEN",
            //   textPrimary: "APPROVAL PENDING",
            //   actionState: "pending",
            //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
            //   linkText: "View Transaction",
            // });

            dispatch(addPendingTransaction());
            dispatch(setShowTransactionStatus(true));
            dispatch(
              setTransactionStatus({
                status: "pending",
                icon: "x_loading",
                content: "Claim Pending",
                showCta: false,
                externalLink: {
                  title: "View on Etherscan",
                  path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                },
                item1: {
                  title: "ZeroLiquid",
                  amount: "ZeroLiquid",
                  icon: ZeroLiquidLogo,
                },
                item2: {
                  title: token2?.symbol,
                  amount: `${
                    // @ts-ignore
                    (ethers.utils.formatUnits(amountDebtToken) * 1).toFixed(5)
                  } ${token2?.symbol}`,
                  icon: token2?.logoUri,
                },
              })
            );

            tx.wait(1)
              .then(function (transactionRecipt: ContractReceipt) {
                onSuccessCallback();
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL SUCCESSFULL",
                //   actionState: "success",
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(false));
                // dispatch(
                //   setTransactionStatus({
                //     status: "success",
                //     icon: "x_check",
                //     content: "Claim Successful",
                //     showCta: true,
                //     externalLink: {
                //       title: "View on Etherscan",
                //       path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //     },
                //     item1: {
                //       title: "ZeroLiquid",
                //       amount: "ZeroLiquid",
                //       icon: ZeroLiquidLogo,
                //     },
                //     item2: {
                //       title: token2?.symbol,
                //       amount: `${
                //         // @ts-ignore
                //         (ethers.utils.formatUnits(amountDebtToken) * 1).toFixed(
                //           5
                //         )
                //       } ${token2?.symbol}`,
                //       icon: token2?.logoUri,
                //     },
                //   })
                // );
                dispatch(setTransactionConfirmationCTA(""));
                dispatch(setShowSnackbar(true));
                dispatch(
                  setSnackbar({
                    title: "Claimed",
                    subTitle: `${
                      (
                        // @ts-ignore
                        ethers.utils.formatEther(amountDebtToken).toString() * 1
                      ).toFixed(5)
                    } ${token2?.symbol} from ZeroLiquid`,
                    icon: ZeroLiquidLogo,
                  })
                );
              })
              .catch((e: any) => {
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL FAILED",
                //   actionState: "error",
                //   error: e,
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(true));
                dispatch(
                  setTransactionStatus({
                    status: "failed",
                    icon: "x_alert-triangle",
                    content: "Claim Failed",
                    showCta: true,
                    externalLink: {
                      title: "",
                      path: "",
                    },
                    item1: {
                      title: "ZeroLiquid",
                      amount: "ZeroLiquid",
                      icon: ZeroLiquidLogo,
                    },
                    item2: {
                      title: token2?.symbol,
                      amount: `${
                        // @ts-ignore
                        (ethers.utils.formatUnits(amountDebtToken) * 1).toFixed(
                          5
                        )
                      } ${token2?.symbol}`,
                      icon: token2?.logoUri,
                    },
                  })
                );
                dispatch(setTransactionConfirmationCTA(""));
              });
          })
          .catch((e: any) => {
            if (e.code === 4001) {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL REJECTED",
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "rejected",
                  icon: "x_alert-triangle",
                  content: "Claim Rejected",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: "ZeroLiquid",
                    amount: "ZeroLiquid",
                    icon: ZeroLiquidLogo,
                  },
                  item2: {
                    title: token2?.symbol,
                    amount: `${
                      // @ts-ignore
                      (ethers.utils.formatUnits(amountDebtToken) * 1).toFixed(5)
                    } ${token2?.symbol}`,
                    icon: token2?.logoUri,
                  },
                })
              );
              dispatch(setTransactionConfirmationCTA(""));
            } else {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL FAILED",
              //   error: e,
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "failed",
                  icon: "x_alert-triangle",
                  content: "Claim Failed",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: "ZeroLiquid",
                    amount: "ZeroLiquid",
                    icon: ZeroLiquidLogo,
                  },
                  item2: {
                    title: token2?.symbol,
                    amount: `${
                      // @ts-ignore
                      (ethers.utils.formatUnits(amountDebtToken) * 1).toFixed(5)
                    } ${token2?.symbol}`,
                    icon: token2?.logoUri,
                  },
                })
              );
              dispatch(setTransactionConfirmationCTA(""));
            }
          });
      }
    },
    [zeroLiquidContractInstance, chainId]
  );

  const burn = useCallback(
    async (
      amount: string,
      recipient: string,
      onSuccessCallback: () => void
    ): Promise<void> => {
      if (zeroLiquidContractInstance && chainId) {
        // console.log({
        //   heading: "Deposit",
        //   textPrimary: "PENDING",
        //   actionState: "pending",
        // });

        zeroLiquidContractInstance
          .burn(amount, recipient)
          .then(function (tx: ContractTransaction) {
            // console.log({
            //   heading: "TOKEN",
            //   textPrimary: "APPROVAL PENDING",
            //   actionState: "pending",
            //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
            //   linkText: "View Transaction",
            // });

            tx.wait(1)
              .then(function (transactionRecipt: ContractReceipt) {
                onSuccessCallback();
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL SUCCESSFULL",
                //   actionState: "success",
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });
              })
              .catch((e: any) => {
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL FAILED",
                //   actionState: "error",
                //   error: e,
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });
              });
          })
          .catch((e: any) => {
            if (e.code === 4001) {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL REJECTED",
              //   actionState: "error",
              // });
            } else {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL FAILED",
              //   error: e,
              //   actionState: "error",
              // });
            }
          });
      }
    },
    [zeroLiquidContractInstance, chainId]
  );

  const repay = useCallback(
    async (
      underlyingToken: Token,
      amount: string,
      recipient: string,
      onSuccessCallback: () => void
    ): Promise<void> => {
      logMessage("repay", [
        underlyingToken,
        amount,
        recipient,
        onSuccessCallback,
      ]);

      if (zeroLiquidContractInstance && chainId) {
        // console.log({
        //   heading: "Deposit",
        //   textPrimary: "PENDING",
        //   actionState: "pending",
        // });

        dispatch(setShowTransactionStatus(true));
        dispatch(
          setTransactionStatus({
            status: "awaiting",
            icon: "x_loading",
            content: "Awaiting confirmation",
            showCta: false,
            externalLink: {
              title: "",
              path: "",
            },
            item1: {
              title: underlyingToken?.symbol,
              amount: `${
                // @ts-ignore
                (ethers.utils.formatEther(amount).toString() * 1).toFixed(5)
              } ${underlyingToken?.symbol}`,
              icon: underlyingToken?.logoUri,
            },
            item2: {
              title: "ZeroLiquid",
              amount: "ZeroLiquid",
              icon: ZeroLiquidLogo,
            },
          })
        );

        zeroLiquidContractInstance
          .repay(underlyingToken.id, amount, recipient)
          .then(function (tx: ContractTransaction) {
            // console.log({
            //   heading: "TOKEN",
            //   textPrimary: "APPROVAL PENDING",
            //   actionState: "pending",
            //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
            //   linkText: "View Transaction",
            // });

            dispatch(addPendingTransaction());
            dispatch(setShowTransactionStatus(true));
            dispatch(
              setTransactionStatus({
                status: "pending",
                icon: "x_loading",
                content: "Payback Pending",
                showCta: false,
                externalLink: {
                  title: "View on Etherscan",
                  path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                },
                item1: {
                  title: underlyingToken?.symbol,
                  amount: `${
                    // @ts-ignore
                    (ethers.utils.formatEther(amount).toString() * 1).toFixed(5)
                  } ${underlyingToken?.symbol}`,
                  icon: underlyingToken?.logoUri,
                },
                item2: {
                  title: "ZeroLiquid",
                  amount: "ZeroLiquid",
                  icon: ZeroLiquidLogo,
                },
              })
            );

            tx.wait(1)
              .then(function (transactionRecipt: ContractReceipt) {
                onSuccessCallback();
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL SUCCESSFULL",
                //   actionState: "success",
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(false));
                // dispatch(
                //   setTransactionStatus({
                //     status: "success",
                //     icon: "x_check",
                //     content: "Payback Successful",
                //     showCta: true,
                //     externalLink: {
                //       title: "View on Etherscan",
                //       path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //     },
                //     item1: {
                //       title: underlyingToken?.symbol,
                //       amount: `${
                //         (
                //           // @ts-ignore
                //           ethers.utils.formatEther(amount).toString() * 1
                //         ).toFixed(5)
                //       } ${underlyingToken?.symbol}`,
                //       icon: underlyingToken?.logoUri,
                //     },
                //     item2: {
                //       title: "ZeroLiquid",
                //       amount: "ZeroLiquid",
                //       icon: ZeroLiquidLogo,
                //     },
                //   })
                // );
                dispatch(setTransactionConfirmationCTA(""));
                dispatch(setShowSnackbar(true));
                dispatch(
                  setSnackbar({
                    title: "Paid back",
                    subTitle: `${
                      // @ts-ignore
                      (ethers.utils.formatEther(amount).toString() * 1).toFixed(
                        5
                      )
                    } ${underlyingToken?.symbol} to ZeroLiquid`,
                    icon: ZeroLiquidLogo,
                  })
                );
              })
              .catch((e: any) => {
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL FAILED",
                //   actionState: "error",
                //   error: e,
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(true));
                dispatch(
                  setTransactionStatus({
                    status: "failed",
                    icon: "x_alert-triangle",
                    content: "Payback Failed",
                    showCta: true,
                    externalLink: {
                      title: "",
                      path: "",
                    },
                    item1: {
                      title: underlyingToken?.symbol,
                      amount: `${
                        (
                          // @ts-ignore
                          ethers.utils.formatEther(amount).toString() * 1
                        ).toFixed(5)
                      } ${underlyingToken?.symbol}`,
                      icon: underlyingToken?.logoUri,
                    },
                    item2: {
                      title: "ZeroLiquid",
                      amount: "ZeroLiquid",
                      icon: ZeroLiquidLogo,
                    },
                  })
                );
              });
          })
          .catch((e: any) => {
            if (e.code === 4001) {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL REJECTED",
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "rejected",
                  icon: "x_alert-triangle",
                  content: "Payback Rejected",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: underlyingToken?.symbol,
                    amount: `${
                      // @ts-ignore
                      (ethers.utils.formatEther(amount).toString() * 1).toFixed(
                        5
                      )
                    } ${underlyingToken?.symbol}`,
                    icon: underlyingToken?.logoUri,
                  },
                  item2: {
                    title: "ZeroLiquid",
                    amount: "ZeroLiquid",
                    icon: ZeroLiquidLogo,
                  },
                })
              );
            } else {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL FAILED",
              //   error: e,
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "failed",
                  icon: "x_alert-triangle",
                  content: "Payback Failed",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: underlyingToken?.symbol,
                    amount: `${
                      // @ts-ignore
                      (ethers.utils.formatEther(amount).toString() * 1).toFixed(
                        5
                      )
                    } ${underlyingToken?.symbol}`,
                    icon: underlyingToken?.logoUri,
                  },
                  item2: {
                    title: "ZeroLiquid",
                    amount: "ZeroLiquid",
                    icon: ZeroLiquidLogo,
                  },
                })
              );
            }
          });
      }
    },
    [zeroLiquidContractInstance, chainId]
  );

  const liquidate = useCallback(
    async (
      yieldToken: Token,
      shares: string,
      amount: string,
      onSuccessCallback: () => void
    ): Promise<void> => {
      logMessage("liquidate", [yieldToken, shares, onSuccessCallback]);

      if (zeroLiquidContractInstance && chainId) {
        // console.log({
        //   heading: "Deposit",
        //   textPrimary: "PENDING",
        //   actionState: "pending",
        // });

        dispatch(setShowTransactionStatus(true));
        dispatch(
          setTransactionStatus({
            status: "awaiting",
            icon: "x_loading",
            content: "Awaiting confirmation",
            showCta: false,
            externalLink: {
              title: "",
              path: "",
            },
            item1: {
              title: yieldToken?.symbol,
              amount: `${amount} ${yieldToken?.symbol}`,
              icon: yieldToken?.logoUri,
            },
            item2: {
              title: "ZeroLiquid",
              amount: "ZeroLiquid",
              icon: ZeroLiquidLogo,
            },
          })
        );

        zeroLiquidContractInstance
          .liquidate(yieldToken.id, shares, minimumAmountOut(shares))
          // .liquidate(yieldToken, shares, minimumAmountOut(amount))
          .then(function (tx: ContractTransaction) {
            // console.log({
            //   heading: "TOKEN",
            //   textPrimary: "APPROVAL PENDING",
            //   actionState: "pending",
            //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
            //   linkText: "View Transaction",
            // });

            dispatch(addPendingTransaction());
            dispatch(setShowTransactionStatus(true));
            dispatch(
              setTransactionStatus({
                status: "pending",
                icon: "x_loading",
                content: "Liquidation Pending",
                showCta: false,
                externalLink: {
                  title: "View on Etherscan",
                  path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                },
                item1: {
                  title: yieldToken?.symbol,
                  amount: `${amount} ${yieldToken?.symbol}`,
                  icon: yieldToken?.logoUri,
                },
                item2: {
                  title: "ZeroLiquid",
                  amount: "ZeroLiquid",
                  icon: ZeroLiquidLogo,
                },
              })
            );

            tx.wait(1)
              .then(function (transactionRecipt: ContractReceipt) {
                onSuccessCallback();
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL SUCCESSFULL",
                //   actionState: "success",
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(false));
                // dispatch(
                //   setTransactionStatus({
                //     status: "success",
                //     icon: "x_check",
                //     content: "Liquidation Successful",
                //     showCta: true,
                //     externalLink: {
                //       title: "View on Etherscan",
                //       path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //     },
                //     item1: {
                //       title: yieldToken?.symbol,
                //       amount: `${amount} ${yieldToken?.symbol}`,
                //       icon: yieldToken?.logoUri,
                //     },
                //     item2: {
                //       title: "ZeroLiquid",
                //       amount: "ZeroLiquid",
                //       icon: ZeroLiquidLogo,
                //     },
                //   })
                // );
                dispatch(setTransactionConfirmationCTA(""));
                dispatch(setShowSnackbar(true));
                dispatch(
                  setSnackbar({
                    title: "Liquidated",
                    subTitle: `${amount} ${yieldToken?.symbol} in ZeroLiquid`,
                    icon: ZeroLiquidLogo,
                  })
                );
              })
              .catch((e: any) => {
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL FAILED",
                //   actionState: "error",
                //   error: e,
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(true));
                dispatch(
                  setTransactionStatus({
                    status: "failed",
                    icon: "x_alert-triangle",
                    content: "Liquidation Failed",
                    showCta: true,
                    externalLink: {
                      title: "",
                      path: "",
                    },
                    item1: {
                      title: yieldToken?.symbol,
                      amount: `${amount} ${yieldToken?.symbol}`,
                      icon: yieldToken?.logoUri,
                    },
                    item2: {
                      title: "ZeroLiquid",
                      amount: "ZeroLiquid",
                      icon: ZeroLiquidLogo,
                    },
                  })
                );
                dispatch(setTransactionConfirmationCTA(""));
              });
          })
          .catch((e: any) => {
            if (e.code === 4001) {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL REJECTED",
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "rejected",
                  icon: "x_alert-triangle",
                  content: "Liquidation Rejected",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: yieldToken?.symbol,
                    amount: `${amount} ${yieldToken?.symbol}`,
                    icon: yieldToken?.logoUri,
                  },
                  item2: {
                    title: "ZeroLiquid",
                    amount: "ZeroLiquid",
                    icon: ZeroLiquidLogo,
                  },
                })
              );
              dispatch(setTransactionConfirmationCTA(""));
            } else {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL FAILED",
              //   error: e,
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "failed",
                  icon: "x_alert-triangle",
                  content: "Liquidation Failed",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: yieldToken?.symbol,
                    amount: `${amount} ${yieldToken?.symbol}`,
                    icon: yieldToken?.logoUri,
                  },
                  item2: {
                    title: "ZeroLiquid",
                    amount: "ZeroLiquid",
                    icon: ZeroLiquidLogo,
                  },
                })
              );
              dispatch(setTransactionConfirmationCTA(""));
            }
          });
      }
    },
    [zeroLiquidContractInstance, chainId]
  );

  const withdrawUnderlying = useCallback(
    async (
      yieldToken: Token,
      shares: string,
      amount: string,
      recipient: string,
      onSuccessCallback: () => void
    ): Promise<void> => {
      logMessage("withdrawUnderlying", [
        yieldToken,
        shares,
        recipient,
        onSuccessCallback,
      ]);

      if (zeroLiquidContractInstance && chainId) {
        // console.log({
        //   heading: "Deposit",
        //   textPrimary: "PENDING",
        //   actionState: "pending",
        // });

        dispatch(setShowTransactionStatus(true));
        dispatch(
          setTransactionStatus({
            status: "awaiting",
            icon: "x_loading",
            content: "Awaiting confirmation",
            showCta: false,
            externalLink: {
              title: "",
              path: "",
            },
            item1: {
              title: yieldToken?.symbol,
              amount: `${amount} ${yieldToken?.symbol}`,
              icon: yieldToken?.logoUri,
            },
            item2: {
              title: "",
              amount: conciseAddress(recipient),
              icon: "",
            },
          })
        );

        zeroLiquidContractInstance
          .withdrawUnderlying(
            yieldToken.id,
            shares,
            recipient,
            minimumAmountOut(shares)
          )
          // .liquidate(yieldToken, shares, minimumAmountOut(amount))
          .then(function (tx: ContractTransaction) {
            // console.log({
            //   heading: "TOKEN",
            //   textPrimary: "APPROVAL PENDING",
            //   actionState: "pending",
            //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
            //   linkText: "View Transaction",
            // });

            dispatch(addPendingTransaction());
            dispatch(setShowTransactionStatus(true));
            dispatch(
              setTransactionStatus({
                status: "pending",
                icon: "x_loading",
                content: "Withdraw Pending",
                showCta: false,
                externalLink: {
                  title: "View on Etherscan",
                  path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                },
                item1: {
                  title: yieldToken?.symbol,
                  amount: `${amount} ${yieldToken?.symbol}`,
                  icon: yieldToken?.logoUri,
                },
                item2: {
                  title: "",
                  amount: conciseAddress(recipient),
                  icon: "",
                },
              })
            );

            tx.wait(1)
              .then(function (transactionRecipt: ContractReceipt) {
                onSuccessCallback();
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL SUCCESSFULL",
                //   actionState: "success",
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(false));
                // dispatch(
                //   setTransactionStatus({
                //     status: "success",
                //     icon: "x_check",
                //     content: "Withdraw Successful",
                //     showCta: true,
                //     externalLink: {
                //       title: "View on Etherscan",
                //       path: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //     },
                //     item1: {
                //       title: yieldToken?.symbol,
                //       amount: `${amount} ${yieldToken?.symbol}`,
                //       icon: yieldToken?.logoUri,
                //     },
                //     item2: {
                //       title: "",
                //       amount: conciseAddress(recipient),
                //       icon: "",
                //     },
                //   })
                // );
                dispatch(setTransactionConfirmationCTA(""));
                dispatch(setShowSnackbar(true));
                dispatch(
                  setSnackbar({
                    title: "Withdrawn",
                    subTitle: `${amount} ${yieldToken?.symbol} from ZeroLiquid`,
                    icon: ZeroLiquidLogo,
                  })
                );
              })
              .catch((e: any) => {
                // console.log({
                //   heading: "TOKEN",
                //   textPrimary: "APPROVAL FAILED",
                //   actionState: "error",
                //   error: e,
                //   link: TRANSACTION_EXPLORER[chainId] + tx.hash,
                //   linkText: "View Transaction",
                // });

                dispatch(subtractPendingTransaction());
                dispatch(setShowTransactionStatus(true));
                dispatch(
                  setTransactionStatus({
                    status: "failed",
                    icon: "x_alert-triangle",
                    content: "Withdraw Failed",
                    showCta: true,
                    externalLink: {
                      title: "",
                      path: "",
                    },
                    item1: {
                      title: yieldToken?.symbol,
                      amount: `${amount} ${yieldToken?.symbol}`,
                      icon: yieldToken?.logoUri,
                    },
                    item2: {
                      title: "",
                      amount: conciseAddress(recipient),
                      icon: "",
                    },
                  })
                );
                dispatch(setTransactionConfirmationCTA(""));
              });
          })
          .catch((e: any) => {
            if (e.code === 4001) {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL REJECTED",
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "rejected",
                  icon: "x_alert-triangle",
                  content: "Withdraw Rejected",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: yieldToken?.symbol,
                    amount: `${amount} ${yieldToken?.symbol}`,
                    icon: yieldToken?.logoUri,
                  },
                  item2: {
                    title: "",
                    amount: conciseAddress(recipient),
                    icon: "",
                  },
                })
              );
              dispatch(setTransactionConfirmationCTA(""));
            } else {
              // console.log({
              //   heading: "TOKEN",
              //   textPrimary: "APPROVAL FAILED",
              //   error: e,
              //   actionState: "error",
              // });

              dispatch(subtractPendingTransaction());
              dispatch(setShowTransactionStatus(true));
              dispatch(
                setTransactionStatus({
                  status: "failed",
                  icon: "x_alert-triangle",
                  content: "Withdraw Failed",
                  showCta: true,
                  externalLink: {
                    title: "",
                    path: "",
                  },
                  item1: {
                    title: yieldToken?.symbol,
                    amount: `${amount} ${yieldToken?.symbol}`,
                    icon: yieldToken?.logoUri,
                  },
                  item2: {
                    title: "",
                    amount: conciseAddress(recipient),
                    icon: "",
                  },
                })
              );
              dispatch(setTransactionConfirmationCTA(""));
            }
          });
      }
    },
    [zeroLiquidContractInstance, chainId]
  );

  return {
    depositUnderlying,
    deposit,
    mint,
    burn,
    repay,
    liquidate,
    withdrawUnderlying,
    getYieldTokenParameters,
    getShares,
    getUnrealizedDebt,
    getRealizedDebt,
    getUnderlyingTokenFromShares,
  };
}
