import { Web3Provider } from "@ethersproject/providers";
import { SafeAppConnector } from "@gnosis.pm/safe-apps-web3-react";
import {
  ALL_SUPPORTED_CHAIN_IDS,
  SupportedChainId,
} from "./../constants/chains";
import { RPC_NETWORK_URLS } from "./../constants/rpc_urls";
import { InjectedConnector } from "@web3-react/injected-connector";
import { PortisConnector } from "@web3-react/portis-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

import ZERO_LIQUID_LOGO_URL from "../assets/images/logo.png";
import getLibrary from "./../utilities/getLibrary";
import { FortmaticConnector } from "./Fortmatic";
import { NetworkConnector } from "./NetworkConnector";

import { FORTMATIC_KEY, PORTIS_KEY } from "./../constants";

export const network = new NetworkConnector({
  urls: RPC_NETWORK_URLS,
  defaultChainId: 1,
});

let networkLibrary: Web3Provider | undefined;
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary(network.provider));
}

export const injected = new InjectedConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
});

export const gnosisSafe = new SafeAppConnector();

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
  rpc: RPC_NETWORK_URLS,
  qrcode: true,
});

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORTMATIC_KEY ?? "",
  chainId: 1,
});

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_KEY ?? "",
  networks: [1],
});

export const walletlink = new WalletLinkConnector({
  url: RPC_NETWORK_URLS[SupportedChainId.GOERLI],
  appName: "Uniswap",
  appLogoUrl: ZERO_LIQUID_LOGO_URL,
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
});
