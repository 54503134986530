import { SupportedChainId } from "./chains";
import { ETHEREUM_RPC_URL } from "./index";

/**
 * These are the network URLs used by the interface when there is not another available source of chain data
 */
export const RPC_NETWORK_URLS: { [key in SupportedChainId]: string } = {
  // [SupportedChainId.MAINNET]: ETHEREUM_RPC_URL,
  // [SupportedChainId.ROPSTEN]: ETHEREUM_RPC_URL,
  // [SupportedChainId.RINKEBY]: ETHEREUM_RPC_URL,
  [SupportedChainId.GOERLI]: ETHEREUM_RPC_URL,
  // [SupportedChainId.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.OPTIMISM]: `https://optimism-mainnet.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.OPTIMISTIC_KOVAN]: `https://optimism-kovan.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.ARBITRUM_ONE]: `https://arbitrum-mainnet.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.ARBITRUM_RINKEBY]: `https://arbitrum-rinkeby.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.POLYGON]: `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.POLYGON_MUMBAI]: `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`,
};
