import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { formatEther } from "../../../utilities/helperFunction";
import { ethers } from "ethers";

const CoinInfoStyle3 = ({ icon, data, getBalance, clickHandler }) => {
  const [balance, setBalance] = useState(0);

  const localBalanceFetching = () => {
    getBalance().then((tokenBalance) => {
      setBalance(tokenBalance);
    });
  };

  useEffect(() => {
    localBalanceFetching();
  }, []);

  return (
    <div
      className={styles.coinInfo}
      onClick={() => clickHandler({ ...data, balance: balance || 0 })}
    >
      <div className={styles.coinInfo__wrapper}>
        <div className={styles.coinInfo__icon}>
          <img
            src={
              icon ||
              "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
            }
            alt="Coin Logo"
          />
        </div>

        <div className={styles.coinInfo__content}>
          <div className={styles.coinInfo__identification}>
            <div className={styles.coinInfo__currencySymbol}>
              <p>{data.symbol || ""}</p>
            </div>

            <div className={styles.coinInfo__name}>
              <p>{data.name || ""}</p>
            </div>
          </div>

          <div className={styles.coinInfo__amount}>
            <p>
              {(ethers.utils.formatEther(balance || 0).toString() * 1).toFixed(
                2
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoinInfoStyle3;
