import React from "react";
import styles from "./index.module.scss";

const TextField2 = ({
  name,
  value,
  placeholder,
  error,
  icon,
  changeHandler,
}) => {
  return (
    <div
      className={`${styles.textField} ${
        error ? styles["textField--error"] : ""
      }`}
    >
      <div className={styles.textField__wrapper}>
        <span className={styles.textField__icon}>
          <span className={icon}></span>
        </span>

        <input
          type="text"
          name={name}
          value={value}
          placeholder={placeholder}
          autoComplete="off"
          error={error ? 'true' : 'false'}
          onChange={changeHandler}
          className={styles.textField__input}
        />
      </div>
    </div>
  );
};

export default TextField2;
