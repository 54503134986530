import React from "react";
import styles from "./index.module.scss";
import Tooltip from "../../Tooltip";

const CoinInfoStyle1 = ({ icon, name, amount, content }) => {
  return (
    <div className={styles.coinInfo}>
      <div className={styles.coinInfo__wrapper}>
        <div className={styles.coinInfo__icon}>
          <img
            src={
              icon ||
              "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
            }
            alt="Coin Logo"
          />
        </div>

        <div className={styles.coinInfo__name}>
          <p>
            {amount ? `${amount} ` : ""}
            {name || ""}
          </p>
        </div>

        {content && (
          <div className={styles.coinInfo__info}>
            <div className={styles.coinInfo__info__icon}>
              <Tooltip direction="bottom" delay={2500} content={content || ""}>
                <span className="x_info-circle"></span>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CoinInfoStyle1;
