import React, { useState } from "react";
import styles from "./index.module.scss";

const Tooltip = (props) => {
  const [active, setActive] = useState(false);

const showTip = () => {
    setActive(true);

    setTimeout(() => {
        setActive(false);
    }, [props.delay || 3000])
}

  return (
    <div className={styles.tooltip}>
      <div
        className={styles.tooltip__wrapper}
        onClick={showTip}
      >
        {/* Wrapping */}
        {props.children}

        {active && (
          <div className={`${styles.tooltip__tip} ${styles[props.direction] || styles.top}`}>
            {/* Content */}
            {props.content}
          </div>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
