if (!process.env.REACT_APP_ENVIRONMENT) {
  throw new Error(
    `REACT_APP_ENVIRONMENT must be a defined environment variable`
  );
}

if (!process.env.REACT_APP_ETHEREUM_RPC) {
  throw new Error(
    `REACT_APP_ETHEREUM_RPC must be a defined environment variable`
  );
}

// if (!process.env.REACT_APP_FORTMATIC_KEY) {
//   throw new Error(
//     `REACT_APP_FORTMATIC_KEY must be a defined environment variable`
//   );
// }

// if (!process.env.REACT_APP_PORTIS_KEY) {
//   throw new Error(
//     `REACT_APP_PORTIS_KEY must be a defined environment variable`
//   );
// }

export const VALID_CHAIN_ID =
  process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ? 1 : 5;

export const ACTIVE_NETWORK = VALID_CHAIN_ID === 1 ? "MAINNET" : "GOERLI";

export const ETHEREUM_RPC_URL = process.env.REACT_APP_ETHEREUM_RPC;

export const FORTMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY;

export const PORTIS_KEY = process.env.REACT_APP_PORTIS_KEY;

// brand links
export const BLOCK_ZERO_WEBSITE = "#";
export const BLOCK_ZERO_FB_GROUP = "#";
export const BLOCK_ZERO_TWITTER = "#";
export const BLOCK_ZERO_DISCORD = "#";
export const BLOCK_ZERO_YOUTUBE = "#";

export const PROXY_ZERO_LIQUID = "0xeae37bcf618143A5D4176e65d60e7Ed9931e6131";
