import React, { Fragment, useEffect, useState } from "react";
import styles from "./index.module.scss";
// Custom Components
import CoinInfoStyle1 from "../../components/CoinInfo/Style1";
import Tag from "../../components/Tag";
import TextField1 from "../../components/TextField/Style1";
import TextField2 from "../../components/TextField/Style2";
import CoinInfoStyle2 from "../../components/CoinInfo/Style2";
import Modal from "../../components/Modal";
import SelectToken from "../../components/ModalScreens/SelectToken";
import Tabs from "../../components/Tabs";
import ProgressS2 from "../../components/Progress/Style2";
import Switch from "../../components/Switch";
import YieldStrategy from "../../components/ModalScreens/YieldStrategy";
import InfoText from "../../components/InfoText";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  setBorrowToken,
  setCollateralToken,
  setYieldStrategyToken,
} from "../../store/features/borrowLoan/borrowLoanSlice";
import { toggleWallet } from "../../store/features/application/applicationSlice";
import { setTokenPrices } from "../../store/features/tokensPrice/tokensPriceSlice";
import {
  setTransactionConfirmation,
  setShowTransactionConfirmation,
} from "../../store/features/transactionStatus/transactionStatusSlice";
// Utilities
import {
  formatDecimalValue,
  formatEther,
  isObjectEmpty,
} from "../../utilities/helperFunction";
// Hooks
import { useERC20ContractFunctions } from "../../hooks/useERC20Contract";
import { useZeroLiquidContract } from "../../hooks/useZeroLiquidContract";
import { ALL_CONTRACT_ADDRESSES } from "../../contract";
// Contants
import {
  DEPOSIT_TOKENS,
  BORROW_TOKENS,
  YIELD_TOKENS,
  MAINNET_TOKENS,
} from "../../constants/tokens";
// Third Party Packages
import { ethers, BigNumber } from "ethers";
import { BigNumber as MyBigNumber } from "bignumber.js";
import axios from "axios";
// Icons
import ZeroLiquidLogo from "../../assets/images/logo-short.png";

const Loans = () => {
  /* -------- Redux -------- */
  const dispatch = useDispatch();
  const activeWallet = useSelector((state) => state.activeWallet);
  const wethPrice = useSelector((state) => state.tokensPrice.wethPrice);
  const wstethPrice = useSelector((state) => state.tokensPrice.wstethPrice);
  const zethPrice = useSelector((state) => state.tokensPrice.zethPrice);
  const transactionConfirmationData = useSelector(
    (state) => state.transactionStatus.transactionConfirmationData
  );
  const selectedCollateralToken = useSelector(
    (state) => state.borrowLoan.selectedCollateralToken
  );
  const selectedBorrowToken = useSelector(
    (state) => state.borrowLoan.selectedBorrowToken
  );
  const yieldStrategyToken = useSelector(
    (state) => state.borrowLoan.yieldStrategyToken
  );

  /* ---------- Hooks -------- */
  const { allowance, approve, balanceOf } = useERC20ContractFunctions();
  const {
    getShares,
    getUnrealizedDebt,
    deposit,
    depositUnderlying,
    mint,
    getYieldTokenParameters,
    getUnderlyingTokenFromShares,
  } = useZeroLiquidContract(ALL_CONTRACT_ADDRESSES[5].PROXY_ZERO_LIQUID);

  /* ------- States -------- */
  const [selectedCollateralBalance, setSelectedCollateralBalance] =
    useState("0");
  const [selectedBorrowBalance, setSelectedBorrowBalance] = useState("0");
  const [collateralAsset, setCollateralAsset] = useState({
    amount: "",
    error: true,
  });
  const [borrowAsset, setBorrowAsset] = useState({
    amount: "",
    error: true,
  });
  const [mintToAnotherWallet, setMintToAnotherWallet] = useState({
    name: "mintToAnotherWallet",
    value: "",
    placeholder: "0x00",
    error: false,
  });
  const [activeTab, setActiveTab] = useState(0);
  const [openSelectTokenModal, setOpenSelectTokenModal] = useState(false);
  const [openYieldStrategyModal, setOpenYieldStrategyModal] = useState(false);
  const [totalShares, setTotalShares] = useState("0");
  const [unRealizedDebt, setUnRealizedDept] = useState("0");
  const [isAllowanceAcceptable, setIsAllowanceAcceptable] = useState(false);
  const [enableSendReward, setEnabledSendReward] = useState(false);
  const [yieldTokenProgress, setYieldTokenProgress] = useState({
    obtainedValue: 0,
    maxValue: 100,
    percentage: 0,
  });
  const [stakeUpfrontYield, setStakeUpfrontYield] = useState("");

  /* -------- Functions --------- */
  const collateralChangeHandler = async (event) => {
    let { value } = event.target;
    let isInValid = false;

    const filteredValue = formatDecimalValue(value);

    await getTokenAllowance(
      // Token
      selectedCollateralToken?.id,
      // Owner
      activeWallet?.address,
      //spender
      ALL_CONTRACT_ADDRESSES[activeWallet?.chainId]?.PROXY_ZERO_LIQUID
    );

    isInValid =
      !BigNumber.from(
        ethers.utils.parseUnits(filteredValue?.toString() || "0")
      ).gt("0") ||
      !BigNumber.from(
        ethers.utils.parseUnits(filteredValue?.toString() || "0")
      ).lte(selectedCollateralBalance || "0");

    setCollateralAsset({
      amount: filteredValue,
      error: isInValid,
    });
  };

  const borrowChangeHandler = (event) => {
    let { value } = event.target;
    let isInValid = false;

    const filteredValue = formatDecimalValue(value);

    if (
      !BigNumber.from(
        ethers.utils.parseUnits(filteredValue?.toString() || "0")
      ).gt("0") ||
      !BigNumber.from(
        ethers.utils.parseUnits(filteredValue?.toString() || "0")
      ).lte(BigNumber.from(unRealizedDebt || "0"))
    ) {
      isInValid = true;
    }

    setBorrowAsset({
      amount: filteredValue,
      error: isInValid,
    });
  };

  const mintToAnotherWalletChangeHandler = (event) => {
    let { value } = event.target;

    console.log(ethers.utils.isAddress(value));

    setMintToAnotherWallet({
      ...mintToAnotherWallet,
      value,
      error: !ethers.utils.isAddress(value),
    });
  };

  const toggleSelectToken = () => {
    setOpenSelectTokenModal((prevState) => !prevState);

    if (activeTab === 0 && openSelectTokenModal) {
      const fakeEvent = {
        target: {
          name: "collateralAsset",
          value: "",
        },
      };

      collateralChangeHandler(fakeEvent);
    }
  };

  const toggleYieldStrategyModal = () => {
    setOpenYieldStrategyModal((prevState) => !prevState);
  };

  const getTokenAllowance = async (tokenId, tokenOwnerId, tokenSpenderId) => {
    // try {
    return new Promise((resolve, reject) => {
      allowance(
        // Token
        tokenId,
        // Owner
        tokenOwnerId,
        //spender
        tokenSpenderId
      )
        .then((allowance) => {
          const VALID_WEI_AMOUNT_BN = ethers.utils.parseUnits(
            collateralAsset?.amount?.toString() || "0"
          );

          const isAllowanceEnough =
            BigNumber.from(VALID_WEI_AMOUNT_BN).lte(
              BigNumber.from(allowance)
            ) && BigNumber.from(VALID_WEI_AMOUNT_BN).gt("0");

          setIsAllowanceAcceptable(isAllowanceEnough);
          resolve(allowance);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const getTokenApproval = async (token, tokenSpenderId, amount) => {
    try {
      return await approve(
        // Token
        token,
        //spender
        tokenSpenderId,
        // Amount
        (amount * 1).toFixed(5),
        // Success Callback
        async () => {
          getTokenAllowance(
            // Token Id
            token?.id,
            activeWallet?.address,
            tokenSpenderId
          ).then((allowance) => {
            const VALID_WEI_AMOUNT_BN = ethers.utils.parseUnits(
              collateralAsset?.amount?.toString() || "0"
            );

            const isAllowanceEnough =
              BigNumber.from(VALID_WEI_AMOUNT_BN?.toString() || "0").lte(
                BigNumber.from(allowance)
              ) &&
              BigNumber.from(VALID_WEI_AMOUNT_BN?.toString() || "0").gt("0");

            setIsAllowanceAcceptable(isAllowanceEnough);
          });
        }
      ).then(() => {});
    } catch (error) {
      console.log(error);
    }
  };

  const getTokensDeposit = async () => {
    try {
      if (
        activeWallet?.address &&
        BigNumber.from(collateralAsset?.balance?.toString() || "0").gte(
          ethers.utils
            .parseUnits(collateralAsset?.amount?.toString() || "0")
            .toString()
        )
      ) {
        if (selectedCollateralToken.symbol === "WETH") {
          await depositUnderlying(
            // Yield Token
            yieldStrategyToken,
            // Amount (wei)
            ethers?.utils?.parseUnits(collateralAsset?.amount)?.toString(),
            // Recipent
            activeWallet?.address,
            // token2
            selectedCollateralToken,
            async () => {
              await localBalanceFetching(
                selectedCollateralToken?.id,
                activeWallet?.address,
                "collateral" // collateral type
              ).then(async () => {
                getSharesFunc(activeWallet.address, yieldStrategyToken.id);
                await setUnRealizedDeptFunc(
                  activeWallet.address,
                  yieldStrategyToken.id
                ).then(() => {
                  toggleTab(1);
                  quickSetBorrowAmount(1);
                });
              });
            }
          );
        } else {
          await deposit(
            // Yield Token
            yieldStrategyToken,
            // Amount (wei)
            ethers?.utils?.parseUnits(collateralAsset?.amount)?.toString(),
            // Recipent
            activeWallet?.address,
            // token2
            selectedCollateralToken,
            async () => {
              await localBalanceFetching(
                selectedCollateralToken?.id,
                activeWallet?.address,
                "collateral" // collateral type
              ).then(async () => {
                await setUnRealizedDeptFunc(
                  activeWallet.address,
                  yieldStrategyToken.id
                ).then(() => {
                  toggleTab(1);
                  quickSetBorrowAmount(1);
                });
              });
            }
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const localBalanceFetching = (tokenId, walletAddress, type) => {
    // try {
    return new Promise((resolve, reject) => {
      balanceOf(tokenId, walletAddress)
        .then((tokenBalance) => {
          if (type === "collateral") {
            setSelectedCollateralBalance(tokenBalance);

            const collateralAssetClone = collateralAsset;
            collateralAssetClone.balance = tokenBalance;
            setCollateralAsset(collateralAssetClone);
          } else {
            setSelectedBorrowBalance(tokenBalance);

            const borrowAssetClone = borrowAsset;
            borrowAssetClone.balance = tokenBalance;
            setBorrowAsset(borrowAssetClone);
          }

          resolve(tokenBalance);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const getSharesFunc = async (walletAddress, yieldTokenId) => {
    return new Promise((resolve, reject) => {
      getShares(walletAddress, yieldTokenId)
        .then((shares) => {
          console.log("Shares: ", shares);
          setTotalShares(shares);
          resolve(shares);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const setUnRealizedDeptFunc = async (walletAddress, yieldTokenId) => {
    return new Promise((resolve, reject) => {
      getUnrealizedDebt(walletAddress, yieldTokenId)
        .then((unRealizedDebt) => {
          setUnRealizedDept(unRealizedDebt.toString());
          resolve(unRealizedDebt);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const quickSetBorrowAmount = async (percentage) => {
    try {
      let x = 1;

      switch (percentage) {
        case 20:
          x = "20";
          break;

        case 30:
          x = "30";
          break;

        default:
          x = "100";
          break;
      }

      if (activeWallet?.address) {
        const calculatedValue = BigNumber.from(unRealizedDebt.toString())
          .mul(x?.toString())
          .div("100")
          .toString();

        const fakeEventObj = {
          target: {
            name: "borrowAsset",
            value: ethers.utils.formatEther(calculatedValue) || "0",
          },
        };

        borrowChangeHandler(fakeEventObj);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleTab = (index) => {
    setActiveTab(index);

    if (index === 1 && activeWallet?.isConnected && yieldStrategyToken?.id) {
      getSharesFunc(activeWallet.address, yieldStrategyToken.id);
      setUnRealizedDeptFunc(activeWallet?.address, yieldStrategyToken.id);
    }
  };

  const borrowTokens = async () => {
    try {
      if (activeWallet?.address) {
        if (BigNumber.from(unRealizedDebt).gt("0")) {
          const borrowAmount = BigNumber.from(
            ethers.utils.parseUnits(borrowAsset?.amount?.toString() || "0")
          ).toString();

          let recipentAddress = activeWallet?.address;

          if (enableSendReward && !mintToAnotherWallet.error) {
            recipentAddress = mintToAnotherWallet.value;
          }

          await mint(
            // Amount
            borrowAmount,
            // Recipent
            recipentAddress,
            yieldStrategyToken,
            selectedBorrowToken,
            // onSuccessCallback
            () => {
              localBalanceFetching(
                selectedBorrowToken?.id,
                activeWallet?.address,
                "borrow" // token type
              ).then(async () => {
                getSharesFunc(activeWallet.address, yieldStrategyToken.id);
                await setUnRealizedDeptFunc(
                  activeWallet.address,
                  yieldStrategyToken.id
                );
                quickSetBorrowAmount(1);
                window.location.href = "/positions/wsteth";
              });
            }
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setCollateralEqualToBalance = () => {
    const fakeEventObj = {
      target: {
        name: "collateralAsset",
        value: ethers.utils.formatEther(selectedCollateralBalance) || "0",
      },
    };

    collateralChangeHandler(fakeEventObj);
  };

  const setBorrowEqualToUnRealizedDebt = () => {
    const fakeEventObj = {
      target: {
        name: "borrowAsset",
        value: ethers.utils.formatEther(unRealizedDebt) || "0",
      },
    };

    borrowChangeHandler(fakeEventObj);
  };

  const toggleSendRewardSwitch = (e) => {
    const { checked } = e.target;
    setEnabledSendReward(checked);
  };

  const calculateYieldStrategyProgress = () => {
    try {
      if (yieldStrategyToken?.id) {
        getYieldTokenParameters(yieldStrategyToken.id).then(
          (yieldTokenParameters) => {
            const { maxValue, obtainedValue } = yieldTokenParameters;

            const maxValueBig = new MyBigNumber(maxValue);
            const obtainedValueBig = new MyBigNumber(obtainedValue);

            setYieldTokenProgress({
              obtainedValue: ethers.utils.formatEther(obtainedValue.toString()),
              maxValue: ethers.utils.formatEther(maxValue.toString()),
              percentage: obtainedValueBig
                .dividedBy(maxValueBig)
                .multipliedBy("100")
                .integerValue()
                .toString(),
            });
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTokenPrices = async () => {
    try {
      const wethPrice = await axios.get(
        `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${MAINNET_TOKENS[0].id}&vs_currencies=usd`
      );

      const wstethPrice = await axios.get(
        `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${MAINNET_TOKENS[1].id}&vs_currencies=usd`
      );

      const zethPrice = await axios.get(
        `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${MAINNET_TOKENS[2].id}&vs_currencies=usd`
      );

      dispatch(
        setTokenPrices({
          wethPrice: wstethPrice?.data[MAINNET_TOKENS[0]?.id]?.usd || "0",
          wstethPrice: zethPrice?.data[MAINNET_TOKENS[1]?.id]?.usd || "0",
          zethPrice: wethPrice?.data[MAINNET_TOKENS[2]?.id]?.usd || "0",
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  /* ---- For Transaction Popups ---- */
  const approvalLocalFunc = () => {
    dispatch(
      setTransactionConfirmation({
        title: "Review Approval",
        item1: {
          title: "Amount",
          amount: (collateralAsset.amount * 1).toFixed(5),
          symbol: selectedCollateralToken?.symbol,
          icon: selectedCollateralToken?.logoUri,
          apy: "",
        },
        item2: {
          title: "Spender",
          amount: "",
          symbol: "ZeroLiquid",
          icon: ZeroLiquidLogo,
          apy: "",
        },
        cta: {
          funcKey: "approve",
          text: "Approve",
          submit: false,
        },
      })
    );
    dispatch(setShowTransactionConfirmation(true));
  };

  const depositLocalFunc = () => {
    dispatch(
      setTransactionConfirmation({
        title: "Review Deposit",
        item1: {
          title: "Amount",
          amount: (collateralAsset.amount * 1).toFixed(5),
          symbol: selectedCollateralToken?.symbol,
          icon: selectedCollateralToken?.logoUri,
          apy: "",
        },
        item2: {
          title: "Strategy",
          amount: "",
          symbol: yieldStrategyToken?.symbol,
          icon: yieldStrategyToken?.logoUri,
          apy: "",
        },
        cta: {
          funcKey: "deposit",
          text: "Deposit",
          submit: false,
        },
      })
    );
    dispatch(setShowTransactionConfirmation(true));
  };

  const borrowLocalFunc = () => {
    dispatch(
      setTransactionConfirmation({
        title: "Review Claim",
        item1: {
          title: "Strategy",
          amount: "",
          symbol: yieldStrategyToken?.symbol,
          icon: yieldStrategyToken?.logoUri,
          apy: "250%",
        },
        item2: {
          title: "Amount",
          amount: (borrowAsset.amount * 1).toFixed(5),
          symbol: selectedBorrowToken.symbol,
          icon: selectedBorrowToken.logoUri,
          apy: "",
        },
        cta: {
          funcKey: "borrow",
          text: "Claim",
          submit: false,
        },
      })
    );
    dispatch(setShowTransactionConfirmation(true));
  };
  /* -------------------------------- */

  /* -------- useEffects --------- */
  // Collateral Token useEffect
  useEffect(() => {
    if (selectedCollateralToken?.id && activeWallet?.address) {
      localBalanceFetching(
        selectedCollateralToken.id,
        activeWallet.address,
        "collateral" // collateral type
      );

      /* Check For Selected Token Allowance */
      getTokenAllowance(
        // Token
        selectedCollateralToken.id,
        // Owner
        activeWallet.address,
        //spender
        ALL_CONTRACT_ADDRESSES[activeWallet.chainId]?.PROXY_ZERO_LIQUID
      );
    }
  }, [activeWallet, selectedCollateralToken, collateralAsset]);

  // Borrow Token useEffect
  useEffect(() => {
    if (selectedBorrowToken?.id && activeWallet?.address) {
      localBalanceFetching(
        selectedBorrowToken?.id,
        activeWallet?.address,
        "borrow" // borrow type
      );
    }
  }, [activeWallet, selectedBorrowToken, borrowAsset]);

  // Yield Strategy Token useEffect
  useEffect(() => {
    if (activeWallet?.address && yieldStrategyToken?.id) {
      getSharesFunc(activeWallet?.address, yieldStrategyToken?.id);
      setUnRealizedDeptFunc(activeWallet?.address, yieldStrategyToken?.id);
      calculateYieldStrategyProgress();
    }
  }, [activeWallet, yieldStrategyToken]);

  // On page load, if selectedCollateralToken is Empty, Set Default Tokens
  useEffect(() => {
    if (isObjectEmpty(selectedCollateralToken)) {
      dispatch(setCollateralToken(DEPOSIT_TOKENS[0]));
      dispatch(setBorrowToken(BORROW_TOKENS[0]));
      dispatch(setYieldStrategyToken(YIELD_TOKENS[0]));
    }

    getTokenPrices();
  }, []);

  // For Executing Transaction Functions
  useEffect(() => {
    if (transactionConfirmationData.cta.submit) {
      switch (transactionConfirmationData.cta.funcKey) {
        case "approve":
          getTokenApproval(
            // Token
            {
              id: selectedCollateralToken?.id,
              name: selectedCollateralToken?.name,
              symbol: selectedCollateralToken?.symbol,
              decimals: selectedCollateralToken?.decimals,
              logoUri: selectedCollateralToken?.logoUri,
            },
            // spender
            ALL_CONTRACT_ADDRESSES[activeWallet.chainId].PROXY_ZERO_LIQUID,
            // Approval Token Amount
            collateralAsset?.amount || ""
          );
          break;
        case "deposit":
          getTokensDeposit();
          break;
        case "borrow":
          borrowTokens();
          break;
        default:
        // code block
      }
    }
  }, [transactionConfirmationData]);

  // For Setting Upfront Yield
  useEffect(() => {
    if (selectedCollateralToken.symbol === "WETH") {
      setStakeUpfrontYield(((collateralAsset?.amount || "0") * 0.1).toString());
    } else if (selectedCollateralToken.symbol === "wstETH") {
      getUnderlyingTokenFromShares(
        selectedCollateralToken?.id,
        ethers.utils.parseUnits(collateralAsset?.amount || "0").toString()
      ).then((shares) => {
        setStakeUpfrontYield(
          ethers.utils.formatUnits(shares || "0") * (0.1).toString()
        );
      });
    }
  }, [activeWallet, collateralAsset, selectedCollateralToken]);

  return (
    <div className="container">
      <div className={`${styles.loanPage} section padding-top-none`}>
        <div className={styles.loanPage__info}>
          <InfoText
            showLink={true}
            text="As this is a beta release, encountering front-end bugs is anticipated. Report bugs"
          />
        </div>

        <div className="exchangeBox m-auto">
          <div className="exchangeBox__wrapper">
            <div>
              <Tabs
                tabs={["Stake", "Claim"]}
                activeTab={activeTab}
                toggleTab={toggleTab}
              />
            </div>

            {activeTab === 0 ? (
              /* Stake Tab Content */
              <Fragment>
                <div className="exchangeBox__content">
                  {/* <div className="exchangeBox__item">
                    <div className={styles.settings}>
                      <span className={styles.settings__btn}>
                        <span className="x_settings-2"></span>
                      </span>
                    </div>

                    <div className={styles.seperator}>
                      <div className="exchangeBox__seperator"></div>
                    </div>
                  </div> */}

                  <div className="exchangeBox__item">
                    <div className="exchangeBox__item__info">
                      <p className="subHeading-1">
                        Select the collateral asset
                      </p>
                    </div>
                    <div className="exchangeBox__card">
                      <div className="exchangeBox__card__topRow">
                        <div className="exchangeBox__card__topCol1">
                          <TextField1
                            name="collateralAsset"
                            value={collateralAsset?.amount}
                            error={collateralAsset?.error}
                            placeholder="0"
                            changeHandler={collateralChangeHandler}
                          />
                        </div>

                        <div className="exchangeBox__card__topCol2">
                          <CoinInfoStyle2
                            icon={selectedCollateralToken?.logoUri}
                            name={selectedCollateralToken.symbol}
                            clickHandler={toggleSelectToken}
                          />
                        </div>
                      </div>

                      <div className="exchangeBox__card__bottomRow">
                        <div className="exchangeBox__card__bottomCol1">
                          <p className="body-2">
                            $
                            {selectedCollateralToken.symbol === "WETH"
                              ? (wethPrice * collateralAsset.amount).toFixed(3)
                              : (wstethPrice * collateralAsset.amount).toFixed(
                                  3
                                )}
                          </p>
                        </div>

                        <div className="exchangeBox__card__bottomCol2">
                          <p
                            className="body-2"
                            style={{ cursor: "pointer" }}
                            onClick={setCollateralEqualToBalance}
                          >
                            {activeWallet?.isConnected ? "Balance: " : ""}

                            {activeWallet?.isConnected
                              ? new MyBigNumber(
                                  formatEther(selectedCollateralBalance || "0")
                                )
                                  .decimalPlaces(3)
                                  .toString()
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="exchangeBox__item">
                    <div className="exchangeBox__item__info">
                      <p className="subHeading-1">Yield Strategy</p>
                    </div>
                    <div className="exchangeBox__card">
                      <div className="exchangeBox__card__topRow">
                        <div className="exchangeBox__card__topCol1">
                          <div className="yieldInfo">
                            <CoinInfoStyle1
                              icon={yieldStrategyToken?.logoUri}
                              name={yieldStrategyToken.symbol}
                            />

                            <Tag
                              title="250% APY"
                              isGreen={true}
                              readOnly={true}
                            />
                          </div>
                        </div>

                        <div className="exchangeBox__card__topCol2">
                          <CoinInfoStyle2
                            icon=""
                            name=""
                            showIcon={false}
                            isDisabled={
                              selectedCollateralToken.symbol === "wstETH"
                            }
                            clickHandler={toggleYieldStrategyModal}
                          />
                        </div>
                      </div>

                      {activeWallet?.address && (
                        <div className="exchangeBox__card__bottomRow">
                          <div className="exchangeBox__card__bottomCol1 exchangeBox__card__fullRow">
                            <ProgressS2
                              title="Vault Utilization"
                              unit="wstETH"
                              value={yieldTokenProgress.obtainedValue}
                              maxValue={yieldTokenProgress.maxValue}
                              percentage={yieldTokenProgress.percentage}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {collateralAsset.amount && (
                    <div className="exchangeBox__item">
                      <p className="body-2 center">
                        Stake{" "}
                        <span>
                          {(collateralAsset?.amount * 1).toFixed(3) || "0"}{" "}
                          {selectedCollateralToken.symbol}
                        </span>{" "}
                        and instantly receive{" "}
                        <span>
                          {stakeUpfrontYield
                            ? (stakeUpfrontYield * 1).toFixed(3)
                            : "-"}{" "}
                          zETH
                        </span>
                        .
                      </p>
                      <p className="body-2 center">
                        This will self repay in <span>2 months</span>.
                      </p>
                    </div>
                  )}
                </div>

                <div className="exchangeBox__cta">
                  <div>
                    <button
                      type="button"
                      className={`button1 ${
                        collateralAsset?.error ? "disabled" : ""
                      }`}
                      disabled={collateralAsset?.error}
                      onClick={
                        activeWallet.isConnected
                          ? isAllowanceAcceptable
                            ? depositLocalFunc
                            : approvalLocalFunc
                          : () => dispatch(toggleWallet())
                      }
                    >
                      <span className="button1__text">
                        {activeWallet.isConnected
                          ? isAllowanceAcceptable
                            ? `Deposit ${selectedCollateralToken.symbol}`
                            : `Approve ${selectedCollateralToken.symbol}`
                          : "Connect Wallet"}
                      </span>
                    </button>
                  </div>
                </div>
              </Fragment>
            ) : (
              /* Claim Tab Content */
              <Fragment>
                <div className="exchangeBox__content">
                  {/* <div className="exchangeBox__item">
                    <div className={styles.settings}>
                      <span className={styles.settings__btn}>
                        <span className="x_settings-2"></span>
                      </span>
                    </div>

                    <div className={styles.seperator}>
                      <div className="exchangeBox__seperator"></div>
                    </div>
                  </div> */}

                  <div className="exchangeBox__item">
                    <div className="exchangeBox__item__info">
                      <p className="subHeading-1">Yield Strategy</p>
                    </div>

                    <div className="exchangeBox__card">
                      <div className="exchangeBox__card__topRow">
                        <div className="exchangeBox__card__topCol1">
                          <div className="yieldInfo">
                            <CoinInfoStyle1
                              icon={yieldStrategyToken?.logoUri}
                              name={yieldStrategyToken.symbol}
                            />

                            <Tag
                              title="250% APY"
                              isGreen={true}
                              readOnly={true}
                            />
                          </div>
                        </div>

                        <div className="exchangeBox__card__topCol2">
                          <CoinInfoStyle2
                            icon=""
                            name=""
                            showIcon={false}
                            clickHandler={toggleYieldStrategyModal}
                          />
                        </div>
                      </div>

                      {/* {activeWallet?.address && (
                        <div className="exchangeBox__card__bottomRow">
                          <div className="exchangeBox__card__bottomCol1 exchangeBox__card__fullRow">
                            <ProgressS2
                              title="Vault Utilization"
                              unit="wstETH"
                              value={yieldTokenProgress.obtainedValue}
                              maxValue={yieldTokenProgress.maxValue}
                              percentage={yieldTokenProgress.percentage}
                            />
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>

                  <div className="exchangeBox__item">
                    <div className="exchangeBox__item__info">
                      <p className="subHeading-1">
                        How much you want to borrow
                      </p>
                    </div>

                    <div className="exchangeBox__card">
                      <div className="exchangeBox__card__topRow">
                        <div className="exchangeBox__card__topCol1">
                          <TextField1
                            name="borrowAsset"
                            value={borrowAsset?.amount}
                            error={borrowAsset?.error}
                            placeholder="0"
                            changeHandler={borrowChangeHandler}
                          />
                        </div>

                        <div className="exchangeBox__card__topCol2">
                          <CoinInfoStyle2
                            icon={selectedBorrowToken?.logoUri}
                            name={selectedBorrowToken.symbol}
                            clickHandler={toggleSelectToken}
                          />
                        </div>
                      </div>

                      <div className="exchangeBox__card__bottomRow">
                        <div className="exchangeBox__card__bottomCol1">
                          <p className="body-2">
                            $
                            {selectedBorrowToken.symbol === "zETH"
                              ? (wethPrice * borrowAsset.amount).toFixed(3)
                              : (wstethPrice * borrowAsset.amount).toFixed(3)}
                          </p>
                        </div>

                        <div className="exchangeBox__card__bottomCol2">
                          <p
                            className="body-2"
                            style={{ cursor: "pointer" }}
                            onClick={setBorrowEqualToUnRealizedDebt}
                          >
                            {activeWallet?.isConnected
                              ? "Available Credit: "
                              : ""}

                            {activeWallet?.isConnected
                              ? (
                                  ethers.utils.formatUnits(
                                    unRealizedDebt?.toString() || "0"
                                  ) * 1
                                ).toFixed(3)
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="exchangeBox__item">
                    <div className={styles.sendReward}>
                      <div className={styles.sendReward__top}>
                        <p className="body-2">Send reward to another wallet</p>

                        <Switch
                          checked={enableSendReward}
                          changeHandler={toggleSendRewardSwitch}
                        />
                      </div>

                      <div
                        className={`${styles.sendReward__bottom} ${
                          enableSendReward
                            ? styles["sendReward__bottom--expand"]
                            : ""
                        }`}
                      >
                        <p className="caption-1">
                          Enter <span>address</span>
                          {/* or{" "}
                          <span>ENS</span> */}
                        </p>

                        <TextField2
                          name={mintToAnotherWallet.name}
                          value={mintToAnotherWallet.value}
                          error={mintToAnotherWallet.error}
                          placeholder={mintToAnotherWallet.placeholder}
                          changeHandler={mintToAnotherWalletChangeHandler}
                        />
                      </div>
                    </div>

                    {/* <div className={styles.seperator}>
                      <div className="exchangeBox__seperator"></div>
                    </div> */}
                  </div>

                  {borrowAsset.amount && (
                    <div className="exchangeBox__item">
                      <p className="body-2 center">
                        You're claiming{" "}
                        <span>
                          {(borrowAsset.amount * 1).toFixed(3) || "0"} zETH.
                        </span>{" "}
                        This is due to be self repaid in <span>1 month</span>.
                      </p>
                    </div>
                  )}
                </div>

                <div className="exchangeBox__cta">
                  <div>
                    <button
                      type="button"
                      className={`button1 ${
                        borrowAsset?.error ||
                        (enableSendReward && mintToAnotherWallet.error)
                          ? "disabled"
                          : ""
                      }`}
                      disabled={
                        borrowAsset?.error ||
                        (enableSendReward && mintToAnotherWallet.error)
                      }
                      onClick={
                        activeWallet.isConnected
                          ? borrowLocalFunc
                          : () => dispatch(toggleWallet())
                      }
                    >
                      <span className="button1__text">
                        {activeWallet.isConnected
                          ? `Claim ${selectedBorrowToken.symbol}`
                          : "Connect Wallet"}
                      </span>
                    </button>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>

      {/* All Modals On This Page Are Below */}
      <Modal isShown={openSelectTokenModal} toggleModal={toggleSelectToken}>
        <SelectToken activeTab={activeTab} closeHandler={toggleSelectToken} />
      </Modal>

      <Modal
        isShown={openYieldStrategyModal}
        toggleModal={toggleYieldStrategyModal}
      >
        <YieldStrategy closeHandler={toggleYieldStrategyModal} />
      </Modal>
      {/* --------------------------------- */}
    </div>
  );
};

export default Loans;
