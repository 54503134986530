import React from "react";
import styles from "./index.module.scss";

const Tabs = ({ tabs, activeTab, toggleTab }) => {
  return (
    <div className={styles.tabs}>
      {tabs && tabs.length
        ? tabs.map((tab, index) => {
            return (
              <button
                key={index}
                type="button"
                className={`${styles.tabs__btn} ${
                  activeTab === index ? styles.active : ""
                }`}
                onClick={() => toggleTab(index)}
              >
                {tab}
              </button>
            );
          })
        : null}
    </div>
  );
};

export default Tabs;
