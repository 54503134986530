import React, { Fragment, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveWallet,
  resetActiveWallet,
} from "../../store/features/activeWallet/activeWalletSlice";
import {
  setTransactionStatus,
  setShowTransactionStatus,
} from "../../store/features/transactionStatus/transactionStatusSlice";
import { SUPPORTED_WALLETS } from "../../constants/wallets";
import { copyToClipBoard } from "../../utilities/helperFunction";
import { ADDRESS_EXPLORER } from "../../constants/chains";

const Wallet = ({ isShown, toggleWallet }) => {
  const dispatch = useDispatch();
  const activeWallet = useSelector((state) => state.activeWallet);
  const [isCopied, setIsCopied] = useState(false);

  const {
    active,
    account,
    library,
    error,
    chainId,
    connector: web3Connector,
    activate: activateConnector,
    deactivate: deactivateConnector,
  } = useWeb3React();

  const handleConnect = (connector, index) => {
    localStorage.setItem("activeConnectorIndex", JSON.stringify(index));
    activateConnector(connector);
  };

  const handleDisconnect = () => {
    deactivateConnector();
    localStorage.removeItem("activeConnectorIndex");
  };

  const handleCopyAddress = () => {
    copyToClipBoard(activeWallet?.address || "");
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, [1500]);
  };

  useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      console.log(error);
      dispatch(setShowTransactionStatus(true));
      dispatch(
        setTransactionStatus({
          status: "rejected",
          icon: "x_alert-triangle",
          content: "Unsupported network, switch to Goerli.",
          showCta: false,
          externalLink: {
            title: "",
            path: "",
          },
        })
      );
    }
  }, [error]);

  useEffect(() => {
    if (active) {
      let walletName = "Unknown Wallet";

      console.log(library.provider);
      
      if (library.provider.isCoinbaseWallet) {
        walletName = "Coinbase";
      } else if (library.provider.isMetaMask) {
        walletName = "Metamask";
      } else if (library.provider.isWalletConnect) {
        walletName = "WalletConnect";
      }
      dispatch(
        setActiveWallet({
          isConnected: true,
          address: account,
          chainId: library?._network?.chainId,
          chainName: library?._network?.name,
          connectedWalletId: walletName, // library?.connection?.url
          connectedWalletName: walletName, // library?.connection?.url
        })
      );
    } else {
      dispatch(resetActiveWallet());
    }
  }, [active, account, library]);

  useEffect(() => {
    const activeConnectorIndex = JSON.parse(
      localStorage.getItem("activeConnectorIndex")
    );

    if (activeConnectorIndex !== null) {
      // This can also be zero which shouyld be taken as true case
      const connector =
        Object.values(SUPPORTED_WALLETS)[activeConnectorIndex]?.connector;
      handleConnect(connector, activeConnectorIndex);
    }
  }, []);

  return (
    <Fragment>
      {isShown && (
        <div className={styles.wallet}>
          <div className={styles.wallet__overlay} onClick={toggleWallet}></div>
          <div className={styles.wallet__wrapper}>
            <div className={styles.wallet__header}>
              <h2 className={`${styles.wallet__title} heading-3`}>
                {activeWallet?.isConnected ? "Your Wallet" : "Connect Wallet"}
              </h2>

              <span className={styles.wallet__closeBtn} onClick={toggleWallet}>
                <span className="x_close-1"></span>
              </span>
            </div>

            <div className={styles.wallet__content}>
              {activeWallet.isConnected ? (
                <Fragment>
                  <div className={styles.wallet__details}>
                    <div className={styles.wallet__details__wrapper}>
                      <div className={styles.walletDetails}>
                        <div className={styles.walletDetails__box}>
                          <div className={styles.walletDetails__top}>
                            <div className={styles.walletDetails__title}>
                              <span className={styles.walletDetails__icon}>
                                <span className="x_wallet-1"></span>
                              </span>

                              <h3>
                                {`${activeWallet?.address?.slice(
                                  0,
                                  6
                                )}...${activeWallet?.address?.slice(-4)}`}
                              </h3>
                            </div>

                            <div className={styles.walletDetails__ctaLink}>
                              <a
                                href={`${ADDRESS_EXPLORER[chainId]}${activeWallet?.address}`}
                                target="_blank"
                                className={styles.walletDetails__icon}
                              >
                                <span className="x_share-1"></span>
                              </a>

                              <span
                                className={`${styles.walletDetails__icon} ${
                                  isCopied
                                    ? styles["walletDetails__icon--active"]
                                    : ""
                                }`}
                                onClick={handleCopyAddress}
                              >
                                <span className="x_copy-1"></span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className={styles.walletDetails__box}>
                          <div className={styles.walletDetails__bottom}>
                            <div className={styles.walletDetails__attribute}>
                              <span>Wallet:</span>
                              <span className={styles.primary}>
                                {activeWallet?.connectedWalletName || ""}
                              </span>
                            </div>

                            <div className={styles.walletDetails__attribute}>
                              <span>Connected network:</span>
                              <span className={styles.primary}>
                                {activeWallet?.chainName || ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={styles.wallet__details__cta}>
                        {/* <button
                          type="button"
                          className="button1"
                          onClick={() => {}}
                        >
                          <span className="button1__text">Switch Wallet</span>
                        </button> */}

                        <button
                          type="button"
                          className="button1"
                          onClick={() => {
                            handleDisconnect(web3Connector);
                            toggleWallet();
                          }}
                        >
                          <span className="button1__text">Disconnect</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <ul className={styles.wallet__list}>
                    {Object.values(SUPPORTED_WALLETS && SUPPORTED_WALLETS)
                      ?.length &&
                      Object.values(SUPPORTED_WALLETS).map(
                        ({ connector, name, iconURL }, index) => {
                          return (
                            <li
                              key={index}
                              className={styles.wallet__listItem}
                              onClick={() => {
                                handleConnect(connector, index);
                                // toggleWallet();
                              }}
                            >
                              <figure className={styles.wallet__listItem__icon}>
                                <img
                                  src={
                                    iconURL ||
                                    "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
                                  }
                                  alt={`${name || "Wallet"} Logo`}
                                />
                              </figure>

                              <span className={styles.wallet__listItem__name}>
                                {name}
                              </span>
                            </li>
                          );
                        }
                      )}
                  </ul>

                  <div className={styles.wallet__info}>
                    <span className={styles.wallet__info__icon}>
                      <span className="x_info-circle-1"></span>
                    </span>

                    <p className={styles.wallet__info__text}>
                      By connecting a wallet, you agree to ZeroLiquid's{" "}
                      <span>Terms of Service</span> and consent to its{" "}
                      <span>Privacy Policy</span>.
                    </p>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Wallet;
