import React from "react";
import styles from "./index.module.scss";

const TextField1 = ({
  name,
  value,
  error,
  readOnly = false,
  placeholder = "",
  changeHandler,
}) => {
  return (
    <div className={styles.textField}>
      <div className={styles.textField__wrapper}>
        <input
          type="text"
          name={name}
          value={value}
          error={error ? "true" : "false"}
          placeholder={placeholder}
          readOnly={readOnly}
          onChange={changeHandler}
          autoComplete="off"
          className={`${styles.textField__input} ${
            error ? styles["textField__input--error"] : ""
          } ${value === "" || Number(value) === 0 ? styles["textField__input--error-white"] : ""}`}
        />
      </div>
    </div>
  );
};

export default TextField1;
