import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";

/* ----- Third Party Packages ----- */
import { NavLink, useParams } from "react-router-dom";
import { ethers, BigNumber } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { BigNumber as MyBigNumber } from "bignumber.js";
import axios from "axios";

/* ----- Custom Components ----- */
import Tag from "../../components/Tag";
import CoinInfoStyle2 from "../../components/CoinInfo/Style2";
import CoinInfoStyle6 from "../../components/CoinInfo/Style6";
import TextField1 from "../../components/TextField/Style1";
import CoinInfoStyle1 from "../../components/CoinInfo/Style1";
import Modal from "../../components/Modal";
import DialogS1 from "../../components/Dialog/Style1";
import Progress from "../../components/Progress";
import TransactionStatusS1 from "../../components/TransactionStatus/Style1";
import Checkbox from "../../components/Checkbox";
import TokenInfoCard from "../../components/TokenInfoCard";
import InfoText from "../../components/InfoText";

/* ----- Redux ----- */
import { useSelector, useDispatch } from "react-redux";
import { setTokenPrices } from "../../store/features/tokensPrice/tokensPriceSlice";

/* ----- Constants ----- */
import { ALL_CONTRACT_ADDRESSES } from "../../contract";
import {
  BORROW_TOKENS,
  DEPOSIT_TOKENS,
  MAINNET_TOKENS,
  YIELD_TOKENS,
} from "../../constants/tokens";

/* ----- Hooks ----- */
import { useZeroLiquidContract } from "../../hooks/useZeroLiquidContract";
import { useERC20ContractFunctions } from "../../hooks/useERC20Contract";

// ----- Utilities -----
import {
  conciseAddress,
  formatDecimalValue,
} from "../../utilities/helperFunction";
import {
  setShowTransactionConfirmation,
  setTransactionConfirmation,
} from "../../store/features/transactionStatus/transactionStatusSlice";
// Icons
import ZeroLiquidLogo from "../../assets/images/logo-short.png";

const PositionSlug = () => {
  /* Hooks */
  const { slug } = useParams();
  const { chainId } = useWeb3React();
  const {
    repay,
    liquidate,
    withdrawUnderlying,
    getShares,
    getRealizedDebt,
    getUnderlyingTokenFromShares,
  } = useZeroLiquidContract(ALL_CONTRACT_ADDRESSES[chainId]?.PROXY_ZERO_LIQUID);
  const { allowance, approve, balanceOf } = useERC20ContractFunctions();

  /* Redux */
  const dispatch = useDispatch();
  const activeWallet = useSelector((state) => state.activeWallet);
  const wethPrice = useSelector((state) => state.tokensPrice.wethPrice);
  const wstethPrice = useSelector((state) => state.tokensPrice.wstethPrice);
  const zethPrice = useSelector((state) => state.tokensPrice.zethPrice);
  const transactionConfirmationData = useSelector(
    (state) => state.transactionStatus.transactionConfirmationData
  );

  /* Variables */
  // There can be three states for transactionStatus i.e: inProgress, success, failed
  const transactionObj = {
    pending: {
      status: "pending",
      icon: "x_loading",
      content: "Waiting for confirmation Borrowing 50 zETH",
      showCta: false,
      externalLink: {},
    },
    success: {
      status: "success",
      icon: "x_check",
      content: "Transaction submitted!",
      showCta: true,
      externalLink: { title: "View on EtherScan", path: "/" },
    },
    failure: {
      status: "failure",
      icon: "x_alert-triangle",
      content: "Transaction Rejected!",
      showCta: true,
      externalLink: {},
    },
  };

  /* States */
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const [isRepayAllowanceEnough, setIsRepayAllowanceEnough] = useState(false);
  const [depositTokenBalance, setDepositTokenBalance] = useState("0");
  const [yieldTokenBalance, setYieldTokenBalance] = useState(0);
  const [totalReliazedDebt, setTotalReliazedDebt] = useState("0");
  const [totalShares, setTotalShares] = useState("0");
  const [totalUnderlyingAmount, setTotalUnderlyingAmount] = useState("0");
  const [positionProgress, setPositionProgress] = useState("0");
  const [activeYieldToken, setActiveYieldToken] = useState({});
  const [collateralAsset, setCollateralAsset] = useState({
    id: activeYieldToken?.id,
    name: activeYieldToken?.name,
    symbol: activeYieldToken?.symbol,
    logoUri: activeYieldToken?.logoUri,
    decimals: activeYieldToken?.decimals,
    amount: "",
    error: false,
  });
  const [borrowAsset, setBorrowAsset] = useState({
    id: BORROW_TOKENS[0].id,
    name: BORROW_TOKENS[0].name,
    symbol: BORROW_TOKENS[0].symbol,
    logoUri: BORROW_TOKENS[0].logoUri,
    decimals: BORROW_TOKENS[0]?.decimals,
    amount: "",
    error: false,
  });
  const [repayAsset, setRepayAsset] = useState({
    id: DEPOSIT_TOKENS[0]?.id,
    name: DEPOSIT_TOKENS[0]?.name,
    symbol: DEPOSIT_TOKENS[0]?.symbol,
    logoUri: DEPOSIT_TOKENS[0]?.logoUri,
    decimals: DEPOSIT_TOKENS[0]?.decimals,
    amount: "",
    balance: 0,
    error: false,
  });
  const [liquidateAsset, setLiquidateAsset] = useState({
    id: YIELD_TOKENS[0]?.id,
    name: YIELD_TOKENS[0]?.name,
    symbol: YIELD_TOKENS[0]?.symbol,
    logoUri: YIELD_TOKENS[0]?.logoUri,
    decimals: YIELD_TOKENS[0]?.decimals,
    amount: "",
    balance: 0,
    error: false,
  });
  const [withdrawAsset, setWithdrawAsset] = useState({
    id: YIELD_TOKENS[0]?.id,
    name: YIELD_TOKENS[0]?.name,
    symbol: YIELD_TOKENS[0]?.symbol,
    logoUri: YIELD_TOKENS[0]?.logoUri,
    decimals: YIELD_TOKENS[0]?.decimals,
    amount: "",
    balance: 0,
    error: false,
  });

  // const [exchangeData, setExchangeData] = useState({
  //   collateralAsset: {
  //     id: DEPOSIT_TOKENS[0]?.id,
  //     name: DEPOSIT_TOKENS[0]?.name,
  //     symbol: DEPOSIT_TOKENS[0]?.symbol,
  //     logoUri: DEPOSIT_TOKENS[0]?.logoUri,
  //     decimals: DEPOSIT_TOKENS[0]?.decimals,
  //     amount: "",
  //     balance: 0,
  //     error: true,
  //   },
  //   borrowAsset: {
  //     id: BORROW_TOKENS[0]?.id,
  //     name: BORROW_TOKENS[0]?.name,
  //     symbol: BORROW_TOKENS[0]?.symbol,
  //     logoUri: BORROW_TOKENS[0]?.logoUri,
  //     decimals: BORROW_TOKENS[0]?.decimals,
  //     amount: "",
  //     balance: 0,
  //     error: true,
  //   },
  // });

  const [openTransactionDialog, setOpenTransactionDialog] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(
    transactionObj.success
  );
  const [isPaybackModalShown, setIsPaybackModalShown] = useState(false);
  const [isLiquidateModalShown, setIsLiquidateModalShown] = useState(false);
  const [isWithdrawModalShown, setIsWithdrawModalShown] = useState(false);

  /* Functions */
  const toggleConsentCheckbox = (event) => {
    if (event !== null) {
      const { checked } = event.target;
      setIsConsentGiven(checked);
    } else {
      setIsConsentGiven(!isConsentGiven);
    }
  };

  const toggleTransactionDialog = () => {
    setOpenTransactionDialog((prevState) => !prevState);
  };

  const togglePaybackModal = () => {
    setIsPaybackModalShown(!isPaybackModalShown);
  };

  const toggleLiquidateModal = () => {
    setIsLiquidateModalShown(!isLiquidateModalShown);
  };

  const toggleWithdrawModal = () => {
    setIsWithdrawModalShown(!isWithdrawModalShown);
  };

  const inputChangeHandler = (event) => {
    let { name, value } = event.target;
    let isInValid = false;

    const filteredValue = formatDecimalValue(value);

    if (name === "repayAsset") {
      if (
        !BigNumber.from(
          ethers.utils.parseUnits(filteredValue?.toString() || "0")
        ).gt("0") ||
        !BigNumber.from(
          ethers.utils.parseUnits(filteredValue?.toString() || "0")
        ).lte(BigNumber.from(totalReliazedDebt || "0")) ||
        !BigNumber.from(depositTokenBalance.toString()).gte(
          BigNumber.from(
            ethers.utils.parseUnits(filteredValue?.toString() || "0")
          )
        )
      ) {
        isInValid = true;
      }

      setRepayAsset({
        ...repayAsset,
        amount: filteredValue,
        error: isInValid,
      });
    } else if (name === "liquidateAsset") {
      if (
        !BigNumber.from(
          ethers.utils.parseUnits(filteredValue?.toString() || "0")
        ).gt("0") ||
        !BigNumber.from(
          ethers.utils.parseUnits(filteredValue?.toString() || "0")
        ).lte(BigNumber.from(totalReliazedDebt || "0"))
      ) {
        isInValid = true;
      }

      setLiquidateAsset({
        ...liquidateAsset,
        amount: filteredValue,
        error: isInValid,
      });
    } else if (name === "withdrawAsset") {
      if (
        !BigNumber.from(
          ethers.utils.parseUnits(filteredValue?.toString() || "0")
        ).gt("0") ||
        !BigNumber.from(
          ethers.utils.parseUnits(filteredValue?.toString() || "0")
        ).lte(BigNumber.from(totalShares || "0"))
      ) {
        isInValid = true;
      }

      setWithdrawAsset({
        ...withdrawAsset,
        amount: value,
        error: isInValid,
      });
    }
  };

  const getRealizedDebtFunc = async (walletAddress) => {
    const result = await getRealizedDebt(walletAddress);
    setTotalReliazedDebt(result);
    return result;
  };

  const getSharesFunc = async (walletAddress, yieldTokenId) => {
    const result = await getShares(walletAddress, yieldTokenId);
    setTotalShares(result);
    return result;
  };

  const quickSetRepayAmount = async (percentage) => {
    try {
      let x = 1;

      switch (percentage) {
        case 25:
          x = "25";
          break;

        case 50:
          x = "50";
          break;

        default:
          x = "100";
          break;
      }

      if (activeWallet?.address) {
        // const realizedDebt = await getRealizedDebtFunc(activeWallet.address);

        const calculatedValue = BigNumber.from(totalReliazedDebt.toString())
          .mul(x)
          .div(100)
          .toString();

        const fakeEventObj = {
          target: {
            name: "repayAsset",
            value: ethers.utils.formatEther(calculatedValue) || "0",
          },
        };

        inputChangeHandler(fakeEventObj);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const quickSetLiquidateAmount = async (percentage) => {
    try {
      let x = 1;

      switch (percentage) {
        case 25:
          x = "25";
          break;

        case 50:
          x = "50";
          break;

        default:
          x = "100";
          break;
      }

      if (activeWallet?.address) {
        // const realizedDebt = await getRealizedDebtFunc(activeWallet.address);

        const calculatedValue = BigNumber.from(totalReliazedDebt.toString())
          .mul(x)
          .div(100)
          .toString();

        const fakeEventObj = {
          target: {
            name: "liquidateAsset",
            value: ethers.utils.formatEther(calculatedValue) || "0",
          },
        };

        inputChangeHandler(fakeEventObj);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const quickSetWithdrawAmount = async (percentage) => {
    try {
      let x = 1;

      switch (percentage) {
        case 25:
          x = "25";
          break;

        case 50:
          x = "50";
          break;

        default:
          x = "100";
          break;
      }

      if (activeWallet?.address) {
        const calculatedValue = BigNumber.from(totalShares.toString())
          .mul(x)
          .div(100)
          .toString();

        const fakeEventObj = {
          target: {
            name: "withdrawAsset",
            value: ethers.utils.formatEther(calculatedValue) || "0",
          },
        };

        inputChangeHandler(fakeEventObj);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTokenAllowance = async (tokenId, tokenOwnerId, tokenSpenderId) => {
    try {
      return await allowance(
        // Token
        tokenId,
        // Owner
        tokenOwnerId,
        //spender
        tokenSpenderId
      ).then((allowance) => {
        const VALID_WEI_AMOUNT_BN = ethers.utils
          .parseUnits(repayAsset?.amount.toString() || "0")
          .toString();

        const isAllowanceEnough = BigNumber.from(VALID_WEI_AMOUNT_BN).lte(
          BigNumber.from(allowance)
        );

        setIsRepayAllowanceEnough(isAllowanceEnough);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getTokenApproval = async (token, tokenSpenderId, amount) => {
    try {
      return await approve(
        // Token
        token,
        //spender
        tokenSpenderId,
        // Amount
        (amount * 1).toFixed(5),
        // Success Callback
        (approval) => {
          getTokenAllowance(
            // Token
            repayAsset?.id,
            // Owner
            activeWallet?.address,
            //spender
            ALL_CONTRACT_ADDRESSES[activeWallet?.chainId]?.PROXY_ZERO_LIQUID
          );
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchWethBalance = async () => {
    if (activeWallet.isConnected) {
      await balanceOf(DEPOSIT_TOKENS[0].id, activeWallet.address).then(
        (wethBalance) => {
          setDepositTokenBalance(wethBalance);
        }
      );
    }
  };

  const fetchTokenAndWalletData = () => {
    balanceOf(collateralAsset.id, activeWallet.address).then((yieldBalance) => {
      setYieldTokenBalance(ethers.utils.formatEther(yieldBalance.toString()));
    });
    getRealizedDebtFunc(activeWallet.address);
    fetchWethBalance();
    getSharesFunc(activeWallet.address, collateralAsset.id).then((shares) => {
      getUnderlyingTokenFromShares(collateralAsset.id, shares).then(
        (underlyingTokenAmount) => {
          setTotalUnderlyingAmount(underlyingTokenAmount);
        }
      );
    });
  };

  const repayTokens = async () => {
    try {
      if (activeWallet.isConnected) {
        await repay(
          // Underlying Token
          repayAsset,
          // Amount
          ethers.utils.parseUnits(repayAsset.amount?.toString() || "0"),
          // Recipent
          activeWallet?.address,
          // onSuccessCallback
          () => {
            if (
              activeWallet?.address &&
              activeWallet?.isConnected &&
              collateralAsset?.id
            ) {
              fetchTokenAndWalletData();
            }
            togglePaybackModal();
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const liquidateTokens = async () => {
    try {
      if (activeWallet.isConnected) {
        await liquidate(
          // Underlying Token
          liquidateAsset,
          // Shares
          BigNumber.from(
            ethers.utils.parseUnits(liquidateAsset.amount?.toString() || "0")
          )
            .mul("2")
            .toString(),
          // Amount
          (liquidateAsset.amount * 1).toFixed(5),
          // onSuccessCallback
          () => {
            if (
              activeWallet?.address &&
              activeWallet?.isConnected &&
              collateralAsset?.id
            ) {
              fetchTokenAndWalletData();
            }
            toggleLiquidateModal();
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const withDrawTokens = async () => {
    try {
      if (activeWallet.isConnected) {
        await withdrawUnderlying(
          // Yield Token
          withdrawAsset,
          // Total Collaterel (Shares)
          totalShares,
          // Amount
          (withdrawAsset.amount * 1).toFixed(5),
          // Recipent
          activeWallet?.address,
          // onSuccessCallback
          () => {
            if (
              activeWallet?.address &&
              activeWallet?.isConnected &&
              collateralAsset?.id
            ) {
              fetchTokenAndWalletData();
            }
            toggleWithdrawModal();
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTokenPrices = async () => {
    try {
      const wethPrice = await axios.get(
        `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${MAINNET_TOKENS[0].id}&vs_currencies=usd`
      );

      const wstethPrice = await axios.get(
        `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${MAINNET_TOKENS[1].id}&vs_currencies=usd`
      );

      const zethPrice = await axios.get(
        `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${MAINNET_TOKENS[2].id}&vs_currencies=usd`
      );

      dispatch(
        setTokenPrices({
          wethPrice: wstethPrice?.data[MAINNET_TOKENS[0]?.id]?.usd || "0",
          wstethPrice: zethPrice?.data[MAINNET_TOKENS[1]?.id]?.usd || "0",
          zethPrice: wethPrice?.data[MAINNET_TOKENS[2]?.id]?.usd || "0",
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  /* ---- For Transaction Popups ---- */
  const approvalLocalFunc = () => {
    dispatch(
      setTransactionConfirmation({
        title: "Review Approval",
        item1: {
          title: "Amount",
          amount: (repayAsset.amount * 1).toFixed(5),
          symbol: repayAsset?.symbol,
          icon: repayAsset?.logoUri,
          apy: "",
        },
        item2: {
          title: "Spender",
          amount: "",
          symbol: "ZeroLiquid",
          icon: ZeroLiquidLogo,
          apy: "",
        },
        cta: {
          funcKey: "approve",
          text: "Approve",
          submit: false,
        },
      })
    );
    dispatch(setShowTransactionConfirmation(true));
  };

  const repayLocalFunc = () => {
    dispatch(
      setTransactionConfirmation({
        title: "Review Repay",
        item1: {
          title: "Amount",
          amount: (repayAsset.amount * 1).toFixed(5),
          symbol: repayAsset?.symbol,
          icon: repayAsset?.logoUri,
          apy: "",
        },
        item2: {
          title: "Spender",
          amount: "",
          symbol: "ZeroLiquid",
          icon: ZeroLiquidLogo,
          apy: "",
        },
        cta: {
          funcKey: "repay",
          text: "Repay",
          submit: false,
        },
      })
    );
    dispatch(setShowTransactionConfirmation(true));
  };

  const liquidateLocalFunc = () => {
    dispatch(
      setTransactionConfirmation({
        title: "Review Liquidate",
        item1: {
          title: "Amount",
          amount: (liquidateAsset.amount * 1).toFixed(5),
          symbol: liquidateAsset?.symbol,
          icon: liquidateAsset?.logoUri,
          apy: "",
        },
        item2: {
          title: "Spender",
          amount: "",
          symbol: "ZeroLiquid",
          icon: ZeroLiquidLogo,
          apy: "",
        },
        cta: {
          funcKey: "liquidate",
          text: "Liquidate",
          submit: false,
        },
      })
    );
    dispatch(setShowTransactionConfirmation(true));
  };

  const withdrawLocalFunc = () => {
    dispatch(
      setTransactionConfirmation({
        title: "Review Withdraw",
        item1: {
          title: "Amount",
          amount: (withdrawAsset.amount * 1).toFixed(5),
          symbol: withdrawAsset?.symbol,
          icon: withdrawAsset?.logoUri,
          apy: "",
        },
        item2: {
          title: "Receiver",
          amount: "",
          symbol: conciseAddress(activeWallet.address),
          icon: "",
          apy: "",
        },
        cta: {
          funcKey: "withdraw",
          text: "Withdraw",
          submit: false,
        },
      })
    );
    dispatch(setShowTransactionConfirmation(true));
  };

  /* -------------------------------- */

  /* useEffects */
  useEffect(() => {
    if (
      activeWallet?.address &&
      activeWallet?.isConnected &&
      collateralAsset?.id
    ) {
      fetchTokenAndWalletData();
    }
  }, [activeWallet, collateralAsset]);

  useEffect(() => {
    setCollateralAsset({
      ...collateralAsset,
      amount: ethers.utils.formatEther(totalShares.toString() || "0"),
    });
    setBorrowAsset({
      ...borrowAsset,
      amount: ethers.utils.formatEther(totalReliazedDebt.toString() || "0"),
    });
    setWithdrawAsset({
      ...withdrawAsset,
      amount: ethers.utils.formatEther(totalShares.toString() || "0"),
    });
  }, [totalShares, totalReliazedDebt]);

  useEffect(() => {
    getTokenAllowance(
      // Token
      repayAsset?.id,
      // Owner
      activeWallet?.address,
      //spender
      ALL_CONTRACT_ADDRESSES[activeWallet?.chainId]?.PROXY_ZERO_LIQUID
    );
  }, [activeWallet, repayAsset]);

  useEffect(() => {
    if (
      BigNumber.from(totalShares).gt("0") &&
      BigNumber.from(totalUnderlyingAmount).gt("0")
    ) {
      const limit = new MyBigNumber(totalUnderlyingAmount).div("10");
      const reliazedDebt = new MyBigNumber(totalReliazedDebt);
      const progressValue = limit
        .minus(reliazedDebt)
        .div(limit)
        .multipliedBy("100")
        .toFormat(2);

      setPositionProgress(progressValue);
    }
  }, [totalReliazedDebt, totalShares, totalUnderlyingAmount]);

  // To Find Current Active Yield Token By Using Page Slug
  useEffect(() => {
    const currentYieldToken = YIELD_TOKENS.find(
      (yieldToken) => yieldToken.symbol.toLowerCase() === slug.toLowerCase()
    );

    const collateralAssetClone = collateralAsset;
    setCollateralAsset({ ...collateralAssetClone, ...currentYieldToken });
    setActiveYieldToken(currentYieldToken);
  }, [slug]);

  useEffect(() => {
    getTokenPrices();
  }, []);

  // For Executing Transaction Functions
  useEffect(() => {
    if (transactionConfirmationData.cta.submit) {
      switch (transactionConfirmationData.cta.funcKey) {
        case "approve":
          getTokenApproval(
            {
              id: repayAsset?.id,
              name: repayAsset?.name,
              symbol: repayAsset?.symbol,
              decimals: repayAsset?.decimals,
              logoUri: repayAsset?.logoUri,
            },
            ALL_CONTRACT_ADDRESSES[chainId]?.PROXY_ZERO_LIQUID,
            // Approval Token Amount
            repayAsset?.amount || ""
          );
          break;
        case "repay":
          repayTokens();
          break;
        case "liquidate":
          liquidateTokens();
          break;
        case "withdraw":
          withDrawTokens();
          break;
        default:
        // code block
      }
    }
  }, [transactionConfirmationData]);

  return (
    <div className="container">
      <div className={`${styles.loanPage} section padding-top-none`}>
        <div className={styles.loanPage__grid}>
          <div className={styles.backBtn}>
            <NavLink to="/positions" className={styles.backBtn__link}>
              <span className={`x_arrow-down-2 ${styles.backBtn__icon}`}></span>
              <span className={styles.backBtn__text}>
                Back to all positions
              </span>
            </NavLink>
          </div>

          <div className={styles.positionCard}>
            <TokenInfoCard
              name={activeYieldToken?.name}
              symbol={activeYieldToken?.symbol}
              logoUri={activeYieldToken?.logoUri}
              description={`This position represents all of your staked ETH in ${
                activeYieldToken?.symbol || ""
              } yield strategy.`}
            />
          </div>

          <div className={`exchangeBox ${styles.positionExchangeBox}`}>
            <div className="exchangeBox__wrapper">
              <div className="exchangeBox__content">
                <div className="exchangeBox__item">
                  <div className="exchangeBox__item__info">
                    <p className="subHeading-1">Total collateral</p>
                  </div>

                  <div className="exchangeBox__card">
                    <div className="exchangeBox__card__topRow">
                      <div className="exchangeBox__card__topCol1">
                        <div className="yieldInfo">
                          <CoinInfoStyle1
                            icon={collateralAsset?.logoUri}
                            name={collateralAsset?.symbol}
                          />

                          <Tag
                            title="250% APY"
                            isGreen={true}
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="exchangeBox__card__bottomRow">
                      <div className="exchangeBox__card__bottomCol1">
                        <p className="heading-4 ">{collateralAsset.amount}</p>
                      </div>

                      <div className="exchangeBox__card__bottomCol2">
                        <p className="caption-2">
                          $
                          {activeYieldToken.symbol === "WETH"
                            ? (wethPrice * collateralAsset.amount).toFixed(3)
                            : (wstethPrice * collateralAsset.amount).toFixed(3)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="exchangeBox__item">
                  <div className="exchangeBox__item__info">
                    <p className="subHeading-1">Debt</p>
                  </div>

                  <div className="exchangeBox__card">
                    <div className="exchangeBox__card__topRow">
                      <div className="exchangeBox__card__topCol1">
                        <CoinInfoStyle1
                          icon={borrowAsset?.logoUri}
                          name={borrowAsset?.symbol}
                        />
                      </div>

                      <div className="exchangeBox__card__topCol2">
                        <Progress
                          statusID="4"
                          value={positionProgress}
                          maxValue={100}
                          title="Debt Repayed" // Loan maturing in 164 days approx
                        />
                      </div>
                    </div>

                    <div className="exchangeBox__card__bottomRow">
                      <div className="exchangeBox__card__bottomCol1">
                        <p className="heading-4 ">{borrowAsset.amount}</p>
                      </div>

                      <div className="exchangeBox__card__bottomCol2">
                        <p className="body-2">
                          ${(zethPrice * borrowAsset.amount).toFixed(3)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {BigNumber.from(
                  ethers.utils.parseUnits(
                    borrowAsset?.amount?.toString() || "0"
                  )
                ).lt("0") && (
                  <div className="exchangeBox__item max-width-none">
                    <InfoText text="Negative value shows debt has been fully repaid and further credit is available." />
                  </div>
                )}
              </div>

              {BigNumber.from(totalReliazedDebt?.toString()).lte("0") ? (
                <div className="exchangeBox__cta">
                  <div>
                    <button
                      type="button"
                      className={`button1 ${
                        BigNumber.from(totalReliazedDebt?.toString()).gt("0")
                          ? "disabled"
                          : ""
                      }`}
                      disabled={BigNumber.from(
                        totalReliazedDebt?.toString()
                      ).gt("0")}
                      onClick={toggleWithdrawModal}
                    >
                      <span className="button1__text">Withdraw</span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="exchangeBox__cta exchangeBox__cta--2">
                  <div>
                    <button
                      type="button"
                      className={`button1 ${
                        BigNumber.from(totalReliazedDebt?.toString()).lte("0")
                          ? "disabled"
                          : ""
                      }`}
                      disabled={BigNumber.from(
                        totalReliazedDebt?.toString()
                      ).lte("0")}
                      onClick={togglePaybackModal}
                    >
                      <span className="button1__text">Payback</span>
                    </button>
                  </div>

                  <div>
                    <button
                      type="button"
                      className={`button1 ${
                        BigNumber.from(totalReliazedDebt?.toString()).lte("0")
                          ? "disabled"
                          : ""
                      }`}
                      disabled={BigNumber.from(
                        totalReliazedDebt?.toString()
                      ).lte("0")}
                      onClick={toggleLiquidateModal}
                    >
                      <span className="button1__text">Self-liquidate</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* All Modals On This Page Are Below */}
      <Modal isShown={isPaybackModalShown} toggleModal={togglePaybackModal}>
        <div className="exchangeBox">
          <div className="exchangeBox__wrapper">
            <div className="exchangeBox__content">
              <div className="exchangeBox__item">
                <div className="exchangeBox__item__info">
                  <p className="subHeading-1">Your debt</p>
                </div>

                <CoinInfoStyle6
                  icon={borrowAsset.logoUri}
                  name={borrowAsset.symbol}
                  amount={borrowAsset.amount}
                  dollarAmount={(zethPrice * borrowAsset.amount).toFixed(3)}
                />
              </div>

              <div className="exchangeBox__item">
                <div className="exchangeBox__item__info">
                  <p className="subHeading-1 subHeadingFlex">
                    <span>How much you want to repay?</span>
                    <span
                      className="caption-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => quickSetRepayAmount(1)}
                    >
                      (Balance:{" "}
                      {(
                        ethers.utils.formatUnits(depositTokenBalance || "0") * 1
                      ).toFixed(3)}{" "}
                      WETH)
                    </span>
                  </p>
                </div>

                <div className="exchangeBox__card">
                  <div className="exchangeBox__card__topRow">
                    <div className="exchangeBox__card__topCol1">
                      <TextField1
                        name="repayAsset"
                        value={repayAsset.amount}
                        error={repayAsset.error}
                        placeholder="0"
                        changeHandler={inputChangeHandler}
                      />
                    </div>

                    <div className="exchangeBox__card__topCol2">
                      <CoinInfoStyle2
                        icon={repayAsset.logoUri}
                        name={repayAsset.symbol}
                        content=""
                        isDropdown={false}
                        clickHandler={() => {}}
                      />
                    </div>
                  </div>

                  <div className="exchangeBox__card__bottomRow">
                    <div className="exchangeBox__card__bottomCol1">
                      <p className="body-2">
                        ${(wethPrice * repayAsset.amount).toFixed(3)}
                      </p>
                    </div>

                    <div className="exchangeBox__card__bottomCol2">
                      <div className="exchangeBox__tags">
                        <Tag
                          title="25%"
                          clickHandler={() => quickSetRepayAmount(25)}
                        />
                        <Tag
                          title="50%"
                          clickHandler={() => quickSetRepayAmount(50)}
                        />
                        <Tag
                          title="Max"
                          clickHandler={() => quickSetRepayAmount(1)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="exchangeBox__cta">
              <div>
                {isRepayAllowanceEnough ? (
                  <button
                    type="button"
                    className={`button1 ${
                      !isRepayAllowanceEnough ||
                      BigNumber.from(
                        ethers.utils.parseUnits(
                          repayAsset.amount?.toString() || "0"
                        )
                      ).lte("0") ||
                      repayAsset.error
                        ? "disabled"
                        : ""
                    }`}
                    disabled={
                      !isRepayAllowanceEnough ||
                      BigNumber.from(
                        ethers.utils.parseUnits(
                          repayAsset.amount?.toString() || "0"
                        )
                      ).lte("0") ||
                      repayAsset.error
                    }
                    onClick={repayLocalFunc}
                  >
                    <span className="button1__text">Repay</span>
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`button1 ${
                      BigNumber.from(
                        ethers.utils.parseUnits(
                          repayAsset.amount?.toString() || "0"
                        )
                      ).lte("0") || repayAsset.error
                        ? "disabled"
                        : ""
                    }`}
                    disabled={
                      BigNumber.from(
                        ethers.utils.parseUnits(
                          repayAsset.amount?.toString() || "0"
                        )
                      ).lte("0") || repayAsset.error
                    }
                    onClick={approvalLocalFunc}
                  >
                    <span className="button1__text">Approve</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isShown={isLiquidateModalShown} toggleModal={toggleLiquidateModal}>
        <div className="exchangeBox">
          <div className="exchangeBox__wrapper">
            <div className="exchangeBox__content">
              <div className="exchangeBox__item">
                <div className="exchangeBox__item__info">
                  <p className="subHeading-1">Your debt</p>
                </div>

                <CoinInfoStyle6
                  icon={borrowAsset.logoUri}
                  name={borrowAsset.symbol}
                  amount={borrowAsset.amount}
                  dollarAmount={(zethPrice * borrowAsset.amount).toFixed(3)}
                />
              </div>

              <div className="exchangeBox__item">
                <div className="exchangeBox__item__info">
                  <p className="subHeading-1">
                    <span>How much you want to liquidate?</span>
                  </p>
                </div>

                <div className="exchangeBox__card">
                  <div className="exchangeBox__card__topRow">
                    <div className="exchangeBox__card__topCol1">
                      <TextField1
                        name="liquidateAsset"
                        value={liquidateAsset.amount}
                        error={liquidateAsset.error}
                        placeholder="0"
                        changeHandler={inputChangeHandler}
                      />
                    </div>

                    <div className="exchangeBox__card__topCol2">
                      <div className="exchangeBox__tags exchangeBox__tags--right">
                        <Tag
                          title="25%"
                          clickHandler={() => quickSetLiquidateAmount(25)}
                        />
                        <Tag
                          title="50%"
                          clickHandler={() => quickSetLiquidateAmount(50)}
                        />
                        <Tag
                          title="Max"
                          clickHandler={() => quickSetLiquidateAmount(1)}
                        />
                      </div>

                      {/* <CoinInfoStyle2
                        icon={liquidateAsset.logoUri}
                        name={liquidateAsset.symbol}
                        content=""
                        isDropdown={false}
                        clickHandler={() => {}}
                      /> */}
                    </div>
                  </div>

                  {/* <div className="exchangeBox__card__bottomRow">
                    <div className="exchangeBox__card__bottomCol1">
                      <p className="body-2">$2500</p>
                    </div>

                    <div className="exchangeBox__card__bottomCol2">
                      <div className="exchangeBox__tags">
                        <Tag
                          title="25%"
                          clickHandler={() => quickSetLiquidateAmount(25)}
                        />
                        <Tag
                          title="50%"
                          clickHandler={() => quickSetLiquidateAmount(50)}
                        />
                        <Tag
                          title="Max"
                          clickHandler={() => quickSetLiquidateAmount(1)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="exchangeBox__item">
                <div className={styles.checkboxArea}>
                  <Checkbox
                    name="activePosCheck"
                    checked={isConsentGiven}
                    changeHandler={toggleConsentCheckbox}
                  />
                  <p
                    onClick={() => toggleConsentCheckbox(null)}
                    className="subHeading-2"
                  >
                    I understand that liquidating will use my deposited
                    collateral to repay the outstanding debt
                  </p>
                </div>
              </div>
            </div>

            <div className="exchangeBox__cta">
              <div>
                <button
                  type="button"
                  className={`button1 ${
                    BigNumber.from(
                      ethers.utils.parseUnits(
                        liquidateAsset.amount?.toString() || "0"
                      )
                    ).lte("0") ||
                    liquidateAsset.error ||
                    !isConsentGiven
                      ? "disabled"
                      : ""
                  }`}
                  disabled={
                    BigNumber.from(
                      ethers.utils.parseUnits(
                        liquidateAsset.amount?.toString() || "0"
                      )
                    ).lte("0") ||
                    liquidateAsset.error ||
                    !isConsentGiven
                  }
                  onClick={liquidateLocalFunc}
                >
                  <span className="button1__text">Liquidate</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isShown={isWithdrawModalShown} toggleModal={toggleWithdrawModal}>
        <div className="exchangeBox">
          <div className="exchangeBox__wrapper">
            <div className="exchangeBox__content">
              <div className="exchangeBox__item">
                <div className="exchangeBox__item__info">
                  <p className="subHeading-1">Your collateral</p>
                </div>

                <CoinInfoStyle6
                  icon={collateralAsset.logoUri}
                  name={collateralAsset.symbol}
                  amount={collateralAsset.amount}
                  dollarAmount="2500"
                />
              </div>

              <div className="exchangeBox__item">
                <div className="exchangeBox__item__info">
                  <p className="subHeading-1">
                    <span>How much you want to withdraw?</span>
                  </p>
                </div>

                <div className="exchangeBox__card">
                  <div className="exchangeBox__card__topRow">
                    <div className="exchangeBox__card__topCol1">
                      <TextField1
                        name="withdrawAsset"
                        value={withdrawAsset.amount}
                        error={withdrawAsset.error}
                        placeholder="0"
                        changeHandler={inputChangeHandler}
                      />
                    </div>

                    <div className="exchangeBox__card__topCol2">
                      <div className="exchangeBox__tags exchangeBox__tags--right">
                        <Tag
                          title="25%"
                          clickHandler={() => quickSetWithdrawAmount(25)}
                        />
                        <Tag
                          title="50%"
                          clickHandler={() => quickSetWithdrawAmount(50)}
                        />
                        <Tag
                          title="Max"
                          clickHandler={() => quickSetWithdrawAmount(1)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="exchangeBox__cta">
              <div>
                <button
                  type="button"
                  className={`button1 ${
                    BigNumber.from(
                      ethers.utils.parseUnits(
                        withdrawAsset.amount?.toString() || "0"
                      )
                    ).lte("0") || withdrawAsset.error
                      ? "disabled"
                      : ""
                  }`}
                  disabled={
                    BigNumber.from(
                      ethers.utils.parseUnits(
                        withdrawAsset.amount?.toString() || "0"
                      )
                    ).lte("0") || withdrawAsset.error
                  }
                  onClick={withdrawLocalFunc}
                >
                  <span className="button1__text">Withdraw</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* All Dialogs On This Page Are Below */}
      <DialogS1
        isShown={openTransactionDialog}
        toggleDialog={toggleTransactionDialog}
      >
        <TransactionStatusS1
          status={transactionStatus.status}
          icon={transactionStatus.icon}
          content={transactionStatus.content}
          showCta={transactionStatus.showCta}
          externalLink={transactionStatus.externalLink}
          toggleDialog={toggleTransactionDialog}
        />
      </DialogS1>
    </div>
  );
};

export default PositionSlug;
