import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactionStatusData: {
    status: "",
    icon: "",
    content: "",
    showCta: false,
    externalLink: {
      title: "",
      path: "",
    },
    item1: {
      title: "",
      amount: "",
      icon: "",
    },
    item2: {
      title: "",
      amount: "",
      icon: "",
    },
  },
  showTransactionStatus: false,
  pendingTransactions: 0,
  transactionConfirmationData: {
    title: "",
    item1: {
      title: "",
      amount: "",
      symbol: "",
      icon: "",
      apy: "",
    },
    item2: {
      title: "",
      amount: "",
      symbol: "",
      icon: "",
      apy: "",
    },
    cta: {
      funcKey: "",
      text: "",
      submit: false,
    },
  },
  showTransactionConfirmation: false,
  showSnackbar: false,
  snackbarData: {
    title: "",
    subTitle: "",
    icon: "",
  },
};

const transactionStatusSlice = createSlice({
  // A name, used in action types
  name: "transactionStatus",
  // The initial state for the reducer
  initialState,
  // An object of "case reducers". Key names will be used to generate actions.
  reducers: {
    setTransactionStatus(state, action) {
      state.transactionStatusData = action.payload;
    },
    toggleTransactionStatus(state) {
      state.showTransactionStatus = !state.showTransactionStatus;
    },
    setShowTransactionStatus(state, action) {
      state.showTransactionStatus = action.payload;
    },

    addPendingTransaction(state) {
      state.pendingTransactions = state.pendingTransactions + 1;
    },
    subtractPendingTransaction(state) {
      state.pendingTransactions = state.pendingTransactions - 1;
    },

    setTransactionConfirmation(state, action) {
      state.transactionConfirmationData = action.payload;
    },
    toggleTransactionConfirmation(state) {
      state.showTransactionConfirmation = !state.showTransactionConfirmation;
    },
    setTransactionConfirmationCTA(state, action) {
      state.transactionConfirmationData.cta.funcKey = action.payload;
    },
    setTransactionConfirmationSubmit(state, action) {
      state.transactionConfirmationData.cta.submit = action.payload;
    },
    setShowTransactionConfirmation(state, action) {
      state.showTransactionConfirmation = action.payload;
    },

    setSnackbar(state, action) {
      state.snackbarData = action.payload;
    },
    toggleSnackbar(state) {
      state.showSnackbar = !state.showSnackbar;
    },
    setShowSnackbar(state, action) {
      state.showSnackbar = action.payload;
    },

    resetTransactionStatus() {
      return initialState;
    },
  },
});

export const {
  setTransactionStatus,
  toggleTransactionStatus,
  setShowTransactionStatus,
  addPendingTransaction,
  subtractPendingTransaction,
  setTransactionConfirmation,
  toggleTransactionConfirmation,
  setShowTransactionConfirmation,
  setTransactionConfirmationCTA,
  setTransactionConfirmationSubmit,
  setSnackbar,
  toggleSnackbar,
  setShowSnackbar,
  resetTransactionStatus,
} = transactionStatusSlice.actions;
export default transactionStatusSlice.reducer;
