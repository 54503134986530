import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isConnected: false,
  address: "",
  chainId: "",
  chainName: "",
  connectedWalletId: "",
  connectedWalletName: "",
  waletBalance: []
};

const activeWalletSlice = createSlice({
  // A name, used in action types
  name: "activeWallet",
  // The initial state for the reducer
  initialState,
  // An object of "case reducers". Key names will be used to generate actions.
  reducers: {
    setActiveWallet(state, action) {
      return action.payload;
    },
    resetActiveWallet() {
      return initialState;
    },
  },
});

export const { setActiveWallet, resetActiveWallet } = activeWalletSlice.actions;
export default activeWalletSlice.reducer;
