import "./App.scss";
import React from "react";
// Libraries
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { Web3ReactProvider } from "@web3-react/core";
import getLibrary from "./utilities/getLibrary";
// Pages
import { Loans, Positions, PositionSlug, Icons } from "./pages";
// Components
import Header from "./components/Header";
// Store
import store from "./store/store";
import BottomNavigation from "./components/BottomNavigation";

// /* wagmi Imports */
// import { WagmiConfig, createClient, configureChains, mainnet } from "wagmi";

// // import { alchemyProvider } from "wagmi/providers/alchemy";
// import { publicProvider } from "wagmi/providers/public";

// import { MetaMaskConnector } from "wagmi/connectors/metaMask";
// // import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
// // import { InjectedConnector } from "wagmi/connectors/injected";
// // import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

// // Configure chains & providers with the Alchemy provider.
// // Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
// const { chains, provider, webSocketProvider } = configureChains(
//   [mainnet],
//   [publicProvider()]
//   // [alchemyProvider({ apiKey: "yourAlchemyApiKey" }), publicProvider()]
// );

// // Set up client
// const client = createClient({
//   autoConnect: true,
//   connectors: [
//     new MetaMaskConnector({ chains }),
//     // new CoinbaseWalletConnector({
//     //   chains,
//     //   options: {
//     //     appName: "wagmi",
//     //   },
//     // }),
//     // new WalletConnectConnector({
//     //   chains,
//     //   options: {
//     //     projectId: "...",
//     //   },
//     // }),
//     // new InjectedConnector({
//     //   chains,
//     //   options: {
//     //     name: "Injected",
//     //     shimDisconnect: true,
//     //   },
//     // }),
//   ],
//   provider,
//   webSocketProvider,
// });

const App: React.FC = () => {
  return (
    <Router>
      <Provider store={store}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <div className="container-fluid">
            <Header />

            <div className="main">
              <Routes>
                <Route path="/" element={<Loans />} />
                <Route path="/icons" element={<Icons />} />
                <Route path="/loans" element={<Loans />} />
                <Route path="/positions" element={<Positions />} />
                <Route path="/positions/:slug" element={<PositionSlug />} />
              </Routes>
            </div>

            <div className="mobileNavigation">
              <BottomNavigation />
            </div>
          </div>
        </Web3ReactProvider>
      </Provider>
    </Router>
  );
};

export default App;
