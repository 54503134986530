import React, { useState } from "react";
import styles from "./index.module.scss";

/* ----- Custom Components ----- */
import Checkbox from "../Checkbox";
import PositionItem from "../PositionItem";

/* ----- Third Party Packages ----- */
import { YIELD_TOKENS } from "../../constants/tokens";

const Listing = () => {
  /* ----- Constants ----- */
  const PROTOCOLS = {
    wstETH: "LIDO",
    swETH: "SWELL",
  };

  /* ------ States ------- */
  const [showActivePositions, setShowActivePositions] = useState(false);

  /* -------- Functions --------- */
  const toggleActivePosCheck = (event) => {
    if (event !== null) {
      const { checked } = event.target;
      setShowActivePositions(checked);
    } else {
      setShowActivePositions((prevState) => !prevState);
    }
  };

  return (
    <div className={styles.listing}>
      <div className={styles.listing__wrapper}>
        <div className={styles.listing__top}>
          {/* <div className={styles.listing__title}>
            <h2 className="heading-3">Your positions</h2>
            <span className={styles.listing__count}>
              <span>1</span>
            </span>
          </div> */}

          <div className={styles.listing__filter}>
            <Checkbox
              name="activePosCheck"
              checked={showActivePositions}
              changeHandler={toggleActivePosCheck}
            />
            <p
              onClick={() => toggleActivePosCheck(null)}
              className="subHeading-2"
            >
              Show only Active positions
            </p>
          </div>
        </div>

        <div className={styles.listing__content}>
          {YIELD_TOKENS && YIELD_TOKENS.length
            ? YIELD_TOKENS.map((yieldToken) => {
                return (
                  <PositionItem
                    key={yieldToken.id}
                    name={PROTOCOLS[yieldToken.symbol]}
                    symbol={yieldToken.symbol}
                    icon={yieldToken.logoUri}
                    progressTitle={yieldToken.progressTitle}
                    showActivePositions={showActivePositions}
                    // tooltipText={isDebtActive ? "Active Position" : "Closed Position"}
                  />
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default Listing;
