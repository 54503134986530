import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import TextField2 from "../../TextField/Style2";
import CoinInfoStyle3 from "../../CoinInfo/Style3";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setBorrowToken,
  setCollateralToken,
} from "../../../store/features/borrowLoan/borrowLoanSlice";
import { DEPOSIT_TOKENS, BORROW_TOKENS } from "../../../constants/tokens";
import { useERC20ContractFunctions } from "../../../hooks/useERC20Contract";

const SelectToken = ({ activeTab, closeHandler }) => {
  const dispatch = useDispatch();
  const activeWallet = useSelector((state) => state.activeWallet);
  const { balanceOf } = useERC20ContractFunctions();
  // const [balances, setBalances] = useState({});

  const [searchInput, setSearchInput] = useState({
    name: "searchInput",
    value: "",
    placeholder: "Search token",
    error: false,
    icon: "x_search-1",
  });
  const [tokenList, setTokenList] = useState([]);

  const inputChangeHandler = (event) => {
    const { value } = event.target;

    setSearchInput({
      ...searchInput,
      value,
    });
  };

  const selectToken = (token) => {
    if (activeTab === 0) {
      dispatch(setCollateralToken(token));
    } else {
      dispatch(setBorrowToken(token));
    }

    closeHandler();
  };

  const getTokenBalance = async (tokenId, walletAddress) => {
    try {
      return await balanceOf(tokenId, walletAddress);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   DEPOSIT_TOKENS.length &&
  //     DEPOSIT_TOKENS.forEach( (token) => {
  //       balanceOf(token?.id, activeWallet?.address).then((value) => {
  //         // let balancesClone = balances;
  //         // balancesClone = { ...balancesClone, [token?.id]: value };
  //         setBalances({ ...balances, [token?.id]: value });
  //       });
  //     });
  // }, []);

  useEffect(() => {
    if (activeTab === 0) {
      setTokenList(DEPOSIT_TOKENS);
    } else {
      setTokenList(BORROW_TOKENS);
    }
  }, [activeTab]);

  return (
    <div className={styles.selectToken}>
      <div className={styles.selectToken__wrapper}>
        <div className={styles.selectToken__header}>
          <h2 className={`${styles.selectToken__title} heading-3`}>
            Select a token
          </h2>

          <span className={styles.selectToken__closeBtn} onClick={closeHandler}>
            <span className="x_close-1"></span>
          </span>
        </div>

        <div className={styles.selectToken__content}>
          <TextField2
            name={searchInput.name}
            value={searchInput.value}
            error={searchInput.error}
            placeholder={searchInput.placeholder}
            icon={searchInput.icon}
            changeHandler={inputChangeHandler}
          />

          {/* <div className="exchangeBox__seperator"></div> */}

          <div className={styles.selectToken__tokenListing}>
            <ul className={styles.selectToken__tokenListing__list}>
              {tokenList && tokenList?.length
                ? tokenList.map((token, index) => {
                    return (
                      <li
                        key={token?.id || index}
                        className={styles.selectToken__tokenListing__item}
                      >
                        <CoinInfoStyle3
                          icon={token.logoUri}
                          data={token}
                          getBalance={() =>
                            getTokenBalance(token?.id, activeWallet?.address)
                          }
                          clickHandler={selectToken}
                        />
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectToken;
