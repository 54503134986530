import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";

const Progress = ({ statusID, maxValue, value, title }) => {
  const [filledPercentage, setFilledPercentage] = useState("0%");

  useEffect(() => {
    if (Number(value) > 100) {
      value = 100;
    } else if (Number(value) < 0) {
      value = 0;
    }

    setFilledPercentage(`${((value / maxValue) * 100).toFixed(0)}%`);
  }, [maxValue, value]);

  return (
    <div className={styles.progress}>
      <div className={styles.progress__wrapper}>
        <div className={styles.progress__top}>
          <span className={styles.progress__title}>{title || ""}</span>
          <span className={styles.progress__percentage}>
            {filledPercentage}
          </span>
        </div>

        <div className={styles.progress__bottom}>
          <div className={styles.progress__bar}>
            <div
              className={`${styles.progress__filled} ${
                styles[`progress__filled--${statusID}`]
              }`}
              style={{ "--filled-width": filledPercentage }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
