import React from "react";
import styles from "./index.module.scss";
import Tag from "../../Tag";

const TransactionConfirmS1 = ({ item1, item2, ctaText, toggleDialog }) => {
  return (
    <div className={styles.transactionConfirmS1}>
      <div className={styles.transactionConfirmS1__top}>
        <div className={styles.transactionConfirmS1__item}>
          <div
            className={`subHeading-1 ${styles.transactionConfirmS1__item__title}`}
          >
            {item1?.title}
          </div>

          <div className={styles.transactionConfirmS1__item__info}>
            <div className={styles.transactionConfirmS1__item__text}>
              <span>
                {item1?.amount} {item1?.symbol}
              </span>

              {item1?.apy && (
                <Tag
                  title="250% APY"
                  isGreen={true}
                  readOnly={true}
                  clickHandler={() => {}}
                />
              )}
            </div>

            {item1?.icon && (
              <div className={styles.transactionConfirmS1__item__icon}>
                <figure>
                  <img
                    src={
                      item1?.icon ||
                      "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
                    }
                    alt={item1?.symbol}
                  />
                </figure>
              </div>
            )}
          </div>
        </div>

        <div className={styles.transactionConfirmS1__item}>
          <div
            className={`subHeading-1 ${styles.transactionConfirmS1__item__title}`}
          >
            {item2?.title}
          </div>

          <div className={styles.transactionConfirmS1__item__info}>
            <div className={styles.transactionConfirmS1__item__text}>
              <span>
                {item2?.amount} {item2?.symbol}
              </span>

              {item2?.apy && (
                <Tag
                  title="250% APY"
                  isGreen={true}
                  readOnly={true}
                  clickHandler={() => {}}
                />
              )}
            </div>

            {item2?.icon && (
              <div className={styles.transactionConfirmS1__item__icon}>
                <figure>
                  <img
                    src={
                      item2?.icon ||
                      "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
                    }
                    alt={item2?.symbol}
                  />
                </figure>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.transactionConfirmS1__bottom}>
        <button
          type="button"
          className="button1 capitalize"
          onClick={toggleDialog}
        >
          <span className="button1__text">{ctaText}</span>
        </button>
      </div>
    </div>
  );
};

export default TransactionConfirmS1;
