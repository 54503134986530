export const iconsConfig: string[] = [
  "x_alert-triangle",
  "x_check",
  "x_chevron-down",
  "x_chevron-up",
  "x_clock",
  "x_close",
  "x_copy",
  "x_edit",
  "x_external-link",
  "x_gas",
  "x_help-circle",
  "x_info-circle",
  "x_loading",
  "x_plus-circle",
  "x_search",
  "x_settings",
  "x_trash",
  "x_wallet",
];
