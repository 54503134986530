import { AbstractConnector } from "@web3-react/abstract-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
// import { FortmaticConnector } from "@web3-react/fortmatic-connector";
// import { PortisConnector } from "@web3-react/portis-connector";

// import INJECTED_ICON_URL from "../assets/wallets/injected.png";
import COINBASE_ICON_URL from "../assets/wallets/coinbase.png";
// import FORTMATIC_ICON_URL from "../assets/wallets/fortmatic.png";
import METAMASK_ICON_URL from "../assets/wallets/metamask.png";
// import PORTIS_ICON_URL from "../assets/wallets/portis.png";
import WALLETCONNECT_ICON_URL from "../assets/wallets/walletconnect.png";

import {
  // fortmatic,
  injected,
  // portis,
  walletconnect,
  walletlink,
} from "../connectors";

export interface WalletInfo {
  connector: AbstractConnector;
  connectorType?: any;
  name: string;
  iconURL: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  // INJECTED: {
  //   connector: injected,
  //   connectorType: InjectedConnector,
  //   name: "Injected",
  //   iconURL: INJECTED_ICON_URL,
  //   description: "Injected web3 provider.",
  //   href: null,
  //   color: "#010101",
  //   primary: true,
  // },
  METAMASK: {
    connector: injected,
    connectorType: InjectedConnector,
    name: "MetaMask",
    iconURL: METAMASK_ICON_URL,
    description: "Easy-to-use browser extension.",
    href: null,
    color: "#E8831D",
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    connectorType: WalletConnectConnector,
    name: "WalletConnect",
    iconURL: WALLETCONNECT_ICON_URL,
    description: "Connect to Trust Wallet, Rainbow Wallet and more...",
    href: null,
    color: "#4196FC",
    mobile: true,
  },
  // WALLET_LINK: {
  //   connector: walletlink,
  //   connectorType: WalletLinkConnector,
  //   name: "Coinbase Wallet",
  //   iconURL: COINBASE_ICON_URL,
  //   description: "Use Coinbase Wallet app on mobile device",
  //   href: null,
  //   color: "#315CF5",
  // },
  COINBASE_LINK: {
    connector: walletlink,
    connectorType: WalletLinkConnector,
    name: "Coinbase",
    iconURL: COINBASE_ICON_URL,
    description: "Open in Coinbase Wallet app.",
    href: "https://go.cb-w.com/mtUDhEZPy1",
    color: "#315CF5",
    mobile: true,
    mobileOnly: true,
  },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   connectorType: FortmaticConnector,
  //   name: "Fortmatic",
  //   iconURL: FORTMATIC_ICON_URL,
  //   description: "Login using Fortmatic hosted wallet",
  //   href: null,
  //   color: "#6748FF",
  //   mobile: true,
  // },
  // Portis: {
  //   connector: portis,
  //   connectorType: PortisConnector,
  //   name: "Portis",
  //   iconURL: PORTIS_ICON_URL,
  //   description: "Login using Portis hosted wallet",
  //   href: null,
  //   color: "#4A6C9B",
  //   mobile: true,
  // },
};
