import React, { Fragment } from "react";
import styles from "./index.module.scss";

const DialogS1 = ({ isShown, toggleDialog, title, children }) => {
  return (
    <Fragment>
      {isShown && (
        <div className={styles.dialog}>
          <div className={styles.dialog__overlay} onClick={toggleDialog}></div>
          <div className={styles.dialog__wrapper}>
            <div className={styles.dialog__header}>
              <div className={styles.dialog__title}>
                <span>{title || ""}</span>
              </div>

              <div className={styles.dialog__closeBtn} onClick={toggleDialog}>
                <span className="x_close-1"></span>
              </div>
            </div>

            <div className={styles.dialog__content}>{children}</div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default DialogS1;
