import React from "react";
import styles from "./index.module.scss";

const CoinInfoStyle2 = ({
  icon,
  name,
  showIcon = true,
  isDropdown = true,
  isDisabled = false,
  clickHandler,
}) => {
  return (
    <div
      className={`${styles.coinInfo} ${
        isDisabled ? styles["coinInfo--disabled"] : ""
      }`}
      onClick={() => {
        !isDisabled && clickHandler();
      }}
    >
      <div className={styles.coinInfo__wrapper}>
        {showIcon && (
          <div className={styles.coinInfo__icon}>
            <img
              src={
                icon ||
                "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
              }
              alt="Coin Logo"
            />
          </div>
        )}

        {name && (
          <div className={styles.coinInfo__name}>
            <p>{name || ""}</p>
          </div>
        )}

        {isDropdown && (
          <div className={styles.coinInfo__info}>
            <div className={styles.coinInfo__info__icon}>
              <span className="x_arrow-down-1"></span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CoinInfoStyle2;
