import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCollateralToken: {},
  selectedBorrowToken: {},
  yieldStrategyToken: {},
};

const borrowLoanSlice = createSlice({
  // A name, used in action types
  name: "borrowLoan",
  // The initial state for the reducer
  initialState,
  // An object of "case reducers". Key names will be used to generate actions.
  reducers: {
    setCollateralToken(state, action) {
      state.selectedCollateralToken = action.payload;
    },
    setBorrowToken(state, action) {
      state.selectedBorrowToken = action.payload;
    },
    setYieldStrategyToken(state, action) {
      state.yieldStrategyToken = action.payload;
    },
    setBorrowLoan(state, action) {
      return action.payload;
    },
    resetBorrowLoan() {
      return initialState;
    },
  },
});

export const {
  setCollateralToken,
  setBorrowToken,
  setYieldStrategyToken,
  setBorrowLoan,
  resetBorrowLoan,
} = borrowLoanSlice.actions;
export default borrowLoanSlice.reducer;
