import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  zethPrice: "0",
  wethPrice: "0",
  wstethPrice: "0",

  // [MAINNET_TOKENS[0].id]: "0",
  // [MAINNET_TOKENS[1].id]: "0",
  // [MAINNET_TOKENS[2].id]: "0",
};

const tokensPriceSlice = createSlice({
  // A name, used in action types
  name: "tokensPrice",
  // The initial state for the reducer
  initialState,
  // An object of "case reducers". Key names will be used to generate actions.
  reducers: {
    setTokenPrices(state, action) {
      return action.payload
    },
  },
});

export const { setTokenPrices } =
  tokensPriceSlice.actions;
export default tokensPriceSlice.reducer;
