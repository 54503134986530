import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Logo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom";

// Custom Components
import Wallet from "../Wallet";
import SpinnerS2 from "../Spinner/Style2";
import DialogS1 from "../Dialog/Style1";
import TransactionConfirmS1 from "../TransactionConfirmation/Style1";
import TransactionStatusS2 from "../TransactionStatus/Style2";
import SnackbarS1 from "../Snackbar/Style1";

// redux
import { useSelector, useDispatch } from "react-redux";
import { toggleWallet } from "../../store/features/application/applicationSlice";
import {
  toggleTransactionStatus,
  toggleTransactionConfirmation,
  setTransactionConfirmationSubmit,
  toggleSnackbar,
} from "../../store/features/transactionStatus/transactionStatusSlice";

const Header = () => {
  /* -------- Redux -------- */
  const dispatch = useDispatch();
  const isWalletShown = useSelector((state) => state.application.isWalletShown);
  const activeWallet = useSelector((state) => state.activeWallet);
  const transactionConfirmationData = useSelector(
    (state) => state.transactionStatus.transactionConfirmationData
  );
  const transactionStatusData = useSelector(
    (state) => state.transactionStatus.transactionStatusData
  );
  const showTransactionConfirmation = useSelector(
    (state) => state.transactionStatus.showTransactionConfirmation
  );
  const showTransactionStatus = useSelector(
    (state) => state.transactionStatus.showTransactionStatus
  );
  const pendingTransactions = useSelector(
    (state) => state.transactionStatus.pendingTransactions
  );
  const showSnackbar = useSelector(
    (state) => state.transactionStatus.showSnackbar
  );
  const snackbarData = useSelector(
    (state) => state.transactionStatus.snackbarData
  );

  /* -------- States -------- */
  const [isScrolled, setIsScrolled] = useState(false);

  /* --------- useEffects --------- */
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setIsScrolled(window.pageYOffset > 40)
      );
    }
  }, []);

  return (
    <div
      className={`${styles.header} ${
        isScrolled ? styles["header--scrolled"] : ""
      }`}
    >
      <div className={styles.header__wrapper}>
        <a href="/" className={styles.header__logo}>
          <figure>
            <img src={Logo} alt="Zero Liquid Logo" />
          </figure>
        </a>

        <div className={styles.header__navigation}>
          <nav className={styles.navigation}>
            <ul className={styles.navigation__list}>
              <li className={styles.navigation__item}>
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? styles.active : "")}
                >
                  <span className={styles.icon}>
                    <span className="x_flash-1"></span>
                  </span>
                  <span className={styles.text}>Loans</span>
                </NavLink>
              </li>
              <li className={styles.navigation__item}>
                <NavLink
                  to="/positions"
                  className={({ isActive }) => (isActive ? styles.active : "")}
                >
                  <span className={styles.icon}>
                    <span className="x_bar-chart-1"></span>
                  </span>
                  <span className={styles.text}>Positions</span>
                </NavLink>
              </li>
              {/* <li className={styles.navigation__item}>
                <NavLink
                  to="/swap"
                  className={({ isActive }) => (isActive ? styles.active : "")}
                >
                  <span className={styles.icon}>
                    <span className="x_coins-swap-1"></span>
                  </span>
                  <span className={styles.text}>Steamer</span>
                </NavLink>
              </li> */}
            </ul>
          </nav>
        </div>

        <div className={styles.header__cta}>
          <div>
            {pendingTransactions > 0 ? (
              <button
                type="button"
                className={`${styles.header__cta__btn} button1 button1--small`}
                onClick={() => {}}
              >
                <span className="button1__text">
                  {pendingTransactions} Pending
                </span>
                <span className="button1__icon button1__loading">
                  <SpinnerS2 />
                </span>
              </button>
            ) : (
              <button
                type="button"
                className={`${styles.header__cta__btn} button1 button1--small`}
                onClick={() => dispatch(toggleWallet())}
              >
                {/* Left Icon */}
                {activeWallet?.isConnected && (
                  <span className="button1__icon button1__icon--left">
                    <span className="x_wallet-1"></span>
                  </span>
                )}

                {/* Text */}
                <span className="button1__text">
                  {activeWallet?.isConnected
                    ? `${activeWallet?.address?.slice(
                        0,
                        6
                      )}...${activeWallet?.address?.slice(-4)}`
                    : "Connect Wallet"}
                </span>

                {/* Right Icon */}
                {activeWallet?.isConnected && (
                  <span className="button1__icon button1__icon--right">
                    <span className="x_arrow-down-2"></span>
                  </span>
                )}
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Wallet */}
      <Wallet
        isShown={isWalletShown}
        toggleWallet={() => dispatch(toggleWallet())}
        isWalletConnected={true}
      />

      {/* All Dialogs On This Page Are Below */}
      <DialogS1
        title={transactionConfirmationData.title}
        isShown={showTransactionConfirmation}
        toggleDialog={() => dispatch(toggleTransactionConfirmation())}
      >
        <TransactionConfirmS1
          item1={transactionConfirmationData.item1}
          item2={transactionConfirmationData.item2}
          ctaText={transactionConfirmationData.cta.text}
          toggleDialog={() => {
            dispatch(toggleTransactionConfirmation());
            dispatch(
              setTransactionConfirmationSubmit(
                transactionConfirmationData.cta.funcKey ? true : false
              )
            );
          }}
        />
      </DialogS1>

      <DialogS1
        title=""
        isShown={showTransactionStatus}
        toggleDialog={() => dispatch(toggleTransactionStatus())}
      >
        <TransactionStatusS2
          status={transactionStatusData.status}
          icon={transactionStatusData.icon}
          content={transactionStatusData.content}
          showCta={transactionStatusData.showCta}
          externalLink={transactionStatusData.externalLink}
          item1={transactionStatusData.item1}
          item2={transactionStatusData.item2}
          toggleDialog={() => dispatch(toggleTransactionStatus())}
        />
      </DialogS1>

      {/* Snackbar */}
      <SnackbarS1
        isShown={showSnackbar}
        title={snackbarData.title}
        subTitle={snackbarData.subTitle}
        icon={snackbarData.icon}
        toggleSnackbar={() => dispatch(toggleSnackbar())}
      />
    </div>
  );
};

export default Header;
