/* Imports */
import { ethers } from "ethers";

/* ************************************************************************* */
/* ********************* 1. Copy To Clipboard Function ********************* */
/* ************************************************************************* */
export const copyToClipBoard = (content) => {
  try {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      alert("Oops! Unable to copy. Make sure you are on a secure Url.");
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
/* ------------------------------------------------------------------------- */

/* ************************************************************************* */
/* ************************ 2. Generates Random GUID *********************** */
/* ************************************************************************* */
export const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  //  return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};
/* ------------------------------------------------------------------------- */

/* ************************************************************************* */
/* ************** 3. Function to Convert Hex to Decimal Value ************** */
/* ************************************************************************* */
export const formatEther = (string) => {
  try {
    return ethers?.utils?.formatEther(string);
  } catch (error) {
    console.log(error);
  }
};
/* ------------------------------------------------------------------------- */

/* ************************************************************************* */
/* **************** 4. Function to If Given Object Is Empty **************** */
/* ************************************************************************* */
export const isObjectEmpty = (givenObject) => {
  try {
    return (
      givenObject &&
      Object.keys(givenObject).length === 0 &&
      givenObject.constructor === Object
    );
  } catch (error) {
    console.log(error);
  }
};

/* ************************************************************************* */
/* ************* 5. Function to Validate Decimal Number Inputs ************* */
/* ************************************************************************* */
export const decimalPlacesValidation = (decimals) => {
  try {
    let validated = true;
    const afterDecimals = value.split(".")[1];

    if (afterDecimals) {
      if (afterDecimals.length > decimals) {
        validated = false;
      }
    }

    return validated;
  } catch (error) {
    console.log(error);
  }
};

/* ************************************************************************* */
/* ************ 6. Function to Remove More Than 1 Decimal Points *********** */
/* ************************************************************************* */
export const formatDecimalValue = (value) => {
  try {
    let localValue = value.replace(/[^0-9.]/g, "");

    let decimalSplitValue = localValue.split(".");
    if (decimalSplitValue.length > 2) {
      decimalSplitValue = [decimalSplitValue[0], decimalSplitValue[1]];
      localValue = decimalSplitValue.join(".");
    }

    decimalSplitValue = localValue.split(".");

    if (decimalSplitValue[1] && decimalSplitValue[1]?.length > 18) {
      decimalSplitValue = [
        decimalSplitValue[0],
        decimalSplitValue[1].slice(0, 18),
      ];
      localValue = decimalSplitValue.join(".");
    }

    return localValue;
  } catch (error) {
    console.log(error);
  }
};

export function conciseNumber(num) {
  let parsed = parseFloat(num);
  if (parsed < 1000) {
    return parsed.toFixed(1);
  } else if (parsed > 999 && parsed < 1000000) {
    return (parsed / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (parsed > 1000000) {
    return (parsed / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (parsed > 1000000000) {
    return (parsed / 1000000000).toFixed(1) + "B";
  }

  return parsed.toString();
}

export const conciseAddress = (address) => {
  if (ethers.utils.isAddress(address)) {
    return `${address.slice(0, 6)}...${address.slice(
      address.length - 3,
      address.length
    )}`;
  }
  return "-";
};
