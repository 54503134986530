import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { formatEther } from "../../../utilities/helperFunction";

const CoinInfoStyle5 = ({ icon, data, getBalance, clickHandler }) => {
  const [balance, setBalance] = useState(0);

  const localBalanceFetching = () => {
    getBalance().then((tokenBalance) => {
      setBalance(tokenBalance);
    });
  };

  useEffect(() => {
    localBalanceFetching();
  }, []);

  return (
    <div
      className={styles.coinInfo}
      onClick={() => clickHandler({ ...data, balance: balance || 0 })}
    >
      <div className={styles.coinInfo__wrapper}>
        <div className={styles.coinInfo__icon}>
          <img
            src={
              icon ||
              "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
            }
            alt="Coin Logo"
          />
        </div>

        <div className={styles.coinInfo__content}>
          <div className={styles.coinInfo__attribute}>
            <div className={styles.coinInfo__attribute__key}>
              <p>{data.symbol || ""}</p>
            </div>

            <div className={styles.coinInfo__attribute__value}>
              <p>{data.name || ""}</p>
            </div>
          </div>

          <div className={styles.coinInfo__attribute}>
            <div
              className={`${styles.coinInfo__attribute__key} ${
                styles[`coinInfo__attribute__key--right`]
              }`}
            >
              <p>250%</p>
            </div>

            <div
              className={`${styles.coinInfo__attribute__value} ${
                styles[`coinInfo__attribute__value--right`]
              }`}
            >
              <p>APY</p>
            </div>
          </div>

          {/* <div className={styles.coinInfo__attribute}>
            <div className={styles.coinInfo__attribute__key}>
              <p>{data.symbol || ""}</p>
            </div>

            <div className={styles.coinInfo__attribute__value}>
              <p>{data.name || ""}</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CoinInfoStyle5;
