import React from "react";
import styles from "./index.module.scss";
import Tooltip from "../Tooltip";

const Status = ({ statusID, statusTitle, tooltipText }) => {
  return (
    <div className={styles.status}>
      <div className={styles.status__wrapper}>
        <span>
          <span
            className={`${styles.status__indicator} ${
              styles[`status__indicator--${statusID}`]
            }`}
          ></span>
        </span>

        <span className={styles.status__title}>{statusTitle}</span>

        {tooltipText && (
          <span>
            <span className={styles.status__info}>
              <Tooltip direction="bottom" delay={2500} content={tooltipText}>
                <span
                  className={`${styles.status__info__icon} x_info-circle`}
                ></span>
              </Tooltip>
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

export default Status;
