import React, { useEffect, Fragment } from "react";
import styles from "./index.module.scss";

import { useSelector } from "react-redux";

const SnackbarS1 = ({ icon, title, subTitle, isShown, toggleSnackbar }) => {
  const showSnackbar = useSelector(
    (state) => state.transactionStatus.showSnackbar
  );

  useEffect(() => {
    setTimeout(() => {
      if (showSnackbar) {
        toggleSnackbar();
      }
    }, 3000);
  }, [showSnackbar]);

  return (
    <Fragment>
      {isShown && (
        <div className={styles.snackbarS1}>
          <div className={styles.snackbarS1__wrapper}>
            <div className={styles.snackbarS1__icon}>
              <figure>
                <img
                  src={
                    icon ||
                    "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
                  }
                  alt=""
                />
              </figure>
            </div>

            <div className={styles.snackbarS1__typography}>
              <span className={styles.snackbarS1__title}>{title || ""}</span>
              <span className={styles.snackbarS1__subTitle}>
                {subTitle || ""}
              </span>
            </div>

            <div className={styles.snackbarS1__close} onClick={toggleSnackbar}>
              <span className="x_close-1"></span>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SnackbarS1;
