import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isWalletShown: false,
};

const applicationSlice = createSlice({
  // A name, used in action types
  name: "application",
  // The initial state for the reducer
  initialState,
  // An object of "case reducers". Key names will be used to generate actions.
  reducers: {
    setApplication(state, action) {
      return action.payload;
    },
    resetApplication() {
      return initialState;
    },
    toggleWallet(state, action) {
      state.isWalletShown = !state.isWalletShown;
    },
  },
});

export const { setApplication, resetApplication, toggleWallet } = applicationSlice.actions;
export default applicationSlice.reducer;
