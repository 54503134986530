import React from "react";
import styles from "./index.module.scss";

const SpinnerS3 = () => {
  return (
    <div className={styles.spinner}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div className={styles.static}></div>
    </div>
  );
};

export default SpinnerS3;
