import React from "react";
import styles from "./index.module.scss";

const Tag = ({ title, readOnly = false, isGreen = false, clickHandler }) => {
  return (
    <div
      className={`${styles.tag} ${isGreen ? styles["tag--green"] : ""}`}
      onClick={clickHandler}
    >
      <div
        className={`${styles.tag__wrapper} ${readOnly ? styles.readOnly : ""}`}
      >
        <p title={title}>{title}</p>
      </div>
    </div>
  );
};

export default Tag;
