import { configureStore } from "@reduxjs/toolkit";
import applicationReducer from "../store/features/application/applicationSlice";
import activeWalletReducer from "../store/features/activeWallet/activeWalletSlice";
import borrowLoanReducer from "../store/features/borrowLoan/borrowLoanSlice";
import tokensPriceReducer from "../store/features/tokensPrice/tokensPriceSlice";
import transactionStatusReducer from "../store/features/transactionStatus/transactionStatusSlice";

const store = configureStore({
  reducer: {
    application: applicationReducer,
    activeWallet: activeWalletReducer,
    borrowLoan: borrowLoanReducer,
    tokensPrice: tokensPriceReducer,
    transactionStatus: transactionStatusReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
