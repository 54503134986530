import React, { Fragment } from "react";
import styles from "./index.module.scss";

const Modal = ({ isShown, toggleModal, children }) => {
  return (
    <Fragment>
      {isShown && (
        <div className={styles.modal}>
          <div className={styles.modal__overlay} onClick={toggleModal}></div>
          <div className={styles.modal__wrapper}>
            {children}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Modal;
