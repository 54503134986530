import React from "react";
import styles from "./index.module.scss";

const SpinnerS2 = () => {
  return (
    <div className={styles.spinner}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div className={styles.static}></div>
    </div>
  );
};

export default SpinnerS2;
