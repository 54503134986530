import { Contract, ContractInterface } from "ethers";
import { getAddress } from "@ethersproject/address";
import { Web3Provider } from "@ethersproject/providers";
import { AddressZero } from "@ethersproject/constants";

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: string): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export function getContractInstance(
  address: string,
  ABI: ContractInterface,
  library: Web3Provider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    library.getSigner(account).connectUnchecked()
  );
}
