import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { guid } from "../../utilities/helperFunction";

const Checkbox = ({ name, checked, changeHandler }) => {
  const [uniqueGuid, setUniqueGuid] = useState("");

  useEffect(() => {
    const myGuid = guid();
    setUniqueGuid(myGuid);
  }, []);

  return (
    <div className={styles.checkbox}>
      <input
        type="checkbox"
        id={uniqueGuid}
        name={name}
        checked={checked}
        onChange={changeHandler}
      />
      <label htmlFor={uniqueGuid}></label>
    </div>
  );
};

export default Checkbox;
