import { Token } from "../types";
// import ETH from "../assets/images/tokens/ETH.webp";
// import stETH from "../assets/images/tokens/stETH.webp";
import wETH from "../assets/images/tokens/wETH.webp";
import wstETH from "../assets/images/tokens/wstETH.webp";
import zETH from "../assets/images/tokens/zETH.webp";

export const DEPOSIT_TOKENS: Token[] = [
  //   GOERLI TESTNET
  {
    id: "0x8df8c7506708be301340b25fc4d928f7829f68e1",
    name: "Wrapped Ether",
    symbol: "WETH",
    decimals: 18,
    logoUri: wETH,
  },
  // {
  //   id: "0xCa757555fA05Ed1F228dE4938A9921C2e3eAAfF9",
  //   name: "Liquid staked Ether 2.0",
  //   symbol: "stETH",
  //   decimals: 18,
  //   logoUri: stETH,
  // },
  {
    id: "0xf9D689e2aBaC0f531360d16D129fA11B331Dc2e0",
    name: "Wrapped liquid staked Ether 2.0",
    symbol: "wstETH",
    decimals: 18,
    logoUri: wstETH,
  },
];

export const BORROW_TOKENS: Token[] = [
  //   GOERLI TESTNET
  {
    id: "0x947d01482466729756eA55FD0825011A94B039A1",
    name: "ZeroLiquid ETH",
    symbol: "zETH",
    decimals: 18,
    logoUri: zETH,
  },
];

export const YIELD_TOKENS: Token[] = [
  //   GOERLI TESTNET
  {
    id: "0xf9D689e2aBaC0f531360d16D129fA11B331Dc2e0",
    name: "Wrapped liquid staked Ether 2.0",
    symbol: "wstETH",
    decimals: 18,
    logoUri: wstETH,
  },
];

export const MAINNET_TOKENS: Token[] = [
  //   MAINNET
  {
    id: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    name: "Wrapped Ether",
    symbol: "WETH",
    decimals: 18,
    logoUri: wETH,
  },
  {
    id: "0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0",
    name: "Wrapped liquid staked Ether 2.0",
    symbol: "wstETH",
    decimals: 18,
    logoUri: wstETH,
  },
  {
    id: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    name: "ZeroLiquid ETH",
    symbol: "zETH",
    decimals: 18,
    logoUri: zETH,
  },
];
