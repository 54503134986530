import React from "react";
import styles from "./index.module.scss";
import { iconsConfig } from "../../configs/iconsConfig";
import { copyToClipBoard } from "../../utilities/helperFunction";

const Icons = () => {
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="section">
          <section className="section">
            <h2 className="heading-1 primary">Icon Fonts Listing</h2>
          </section>

          <div className="section">
            <div className={styles.iconGrid}>
              {iconsConfig &&
                iconsConfig.map((icon, index) => {
                  return (
                    <div key={index} className={styles.icon}>
                      <div className={styles.icon__wrapper}>
                        <div className={styles.icon__item}>
                          <span className={`${icon}`}></span>
                        </div>
                        <div className={styles.icon__name}>
                          <p>{icon}</p>
                        </div>
                        <button
                          type="button"
                          className={styles.icon__codeCopy}
                          onClick={() => copyToClipBoard(`<span className='${icon}'></span>`)}
                        >
                          Copy Code
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Icons;
