import React from "react";
import styles from "./index.module.scss";
import Tooltip from "../../Tooltip";

const CoinInfoStyle6 = ({ icon, name, amount, dollarAmount }) => {
  return (
    <div className={styles.coinInfo}>
      <div className={styles.coinInfo__wrapper}>
        <div className={styles.coinInfo__icon}>
          <img
            src={
              icon ||
              "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
            }
            alt="Coin Logo"
          />
        </div>

        <div className={styles.coinInfo__right}>
          <div className={styles.coinInfo__name}>
            <p>
              {amount ? `${amount} ` : ""}
              {name || ""}
            </p>
          </div>

          <div className={styles.coinInfo__dollarValue}>
            <p>${dollarAmount || "0"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoinInfoStyle6;
