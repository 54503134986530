import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { conciseNumber } from "../../../utilities/helperFunction";

const ProgressS2 = ({ maxValue, value, title, unit, percentage }) => {
  const [filledPercentage, setFilledPercentage] = useState("0%");

  useEffect(() => {
    // if (Number(value) > 100) {
    //   value = 100;
    // } else if (Number(value) < 0) {
    //   value = 0;
    // }

    if(percentage) {
      setFilledPercentage(`${(percentage)}%`)
    } else {
      setFilledPercentage(`${((value / maxValue) * 100).toFixed(0)}%`);
    }

  }, [maxValue, value, percentage]);

  return (
    <div className={styles.progress}>
      <div className={styles.progress__wrapper}>
        <div className={styles.progress__top}>
          <span className={styles.progress__title}>{title || ""}</span>
          <span className={styles.progress__unit}>
            {conciseNumber(value)} / {conciseNumber(maxValue)} {unit}
          </span>
        </div>

        <div className={styles.progress__bottom}>
          <div className={styles.progress__bar}>
            <div
              className={styles.progress__filled}
              style={{ "--filled-width": filledPercentage }}
            ></div>

            <span className={styles.progress__percentage}>
              {filledPercentage}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressS2;
