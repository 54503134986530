import { useCallback } from "react";
import { WSTETH_ADAPTER_ABI } from "../contract";
import { useContract } from "./useContract";
import { Contract, ContractReceipt, ContractTransaction } from "ethers";

export function useWstETHAdapterContract(address: string): {
  price: () => Promise<string>;
} {
  const adapterContractInstance = useContract(
    address,
    WSTETH_ADAPTER_ABI
  ) as Contract; // Gives contrast instance

  const price = useCallback(async (): Promise<string> => {
    return await (await adapterContractInstance.price()).toString();
  }, [adapterContractInstance]);

  return {
    price,
  };
}
