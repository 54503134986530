import React from "react";
import styles from "./index.module.scss";

const TokenInfoCard = ({ name, symbol, logoUri, description }) => {
  return (
    <div className={styles.tokenInfoCard}>
      <div className={styles.tokenInfoCard__wrapper}>
        <div className={styles.tokenInfoCard__top}>
          <span className={styles.tokenInfoCard__name}>{name}</span>

          <span className={styles.tokenInfoCard__symbol}>{symbol}</span>
        </div>

        <div className={styles.tokenInfoCard__bottom}>
          <div className={styles.tokenInfoCard__logo}>
            <figure>
              <img src={logoUri} alt={name} />
            </figure>
          </div>

          <div className={styles.tokenInfoCard__description}>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenInfoCard;
